import { LOADING_MATCHES, SELECTED_MATCH, SET_MATCHES } from "./types";
import { MatchPhone } from "refsix-js-models";

export const setMatches = (matches: MatchPhone[]) => ({
    type: SET_MATCHES,
    matches,
});

export const setSelectedMatch = (match: MatchPhone | undefined) => ({
    type: SELECTED_MATCH,
    match,
});

export const setLoadingMatches = (loading: boolean) => ({
    type: LOADING_MATCHES,
    loading,
});
