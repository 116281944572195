import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: false,
        lng: navigator.language,
        resources: {
            en: { translation: require("./translations/en.json") },
            us: { translation: require("./translations/us.json") },
            da: { translation: require("./translations/da.json") },
            de: { translation: require("./translations/de.json") },
            fr: { translation: require("./translations/fr.json") },
            jp: { translation: require("./translations/jp.json") },
            nl: { translation: require("./translations/nl.json") },
            no: { translation: require("./translations/no.json") },
            pt: { translation: require("./translations/pt.json") },
        },
        react: { useSuspense: true },
    });
