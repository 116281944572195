import { MatchPhone } from "refsix-js-models";
import { Moment } from "moment";

export class Matches {
    matches: MatchPhone[] = [];
    match?: MatchPhone;
    loading?: boolean;
}
export interface Competitions {
    selectedCompetition: string;
    competitionFilter: string;
    competitions: string[];
}

export interface SelectedDay {
    selectedDay: Moment;
    startTs: number;
    endTs: number;
}
export interface AppState {
    matches: Matches;
    competitions: Competitions;
    selectedDay: SelectedDay;
}
