import { Middleware } from "redux";
import { AppState } from "../AppState";
import { SET_MATCHES, SET_SELECTED_DAY } from "../actions/types";
import { setLoadingMatches } from "../actions/matches";

export const loadingMiddleware: Middleware<{}, AppState> =
    (store) => (next) => (action) => {
        // check if any matches need updating
        switch (action.type) {
            case SET_SELECTED_DAY:
                store.dispatch(setLoadingMatches(true));
                break;

            case SET_MATCHES:
                store.dispatch(setLoadingMatches(false));
                break;

            default:
            // do nothing
        }

        next(action);
    };
