import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

// export const useAllCompetitions = () => {
//     const matches = useSelector((state: RootState) => state.matches.matches);
//     const allCompetitions = compact(uniq(map("competition", matches)));
//     return allCompetitions;
// };
export const useAllMatches = () =>
    useSelector((state: RootState) => state.matches.matches);

export const useCompetitions = () =>
    useSelector((state: RootState) => state.competitions.competitions);

export const useCompetitionFilter = () =>
    useSelector((state: RootState) => state.competitions.competitionFilter);
export const useMatches = () => {
    return useAllMatches();
    // const competitions = useCompetitions();
    // const matches = useAllMatches();
    // const allCompetitions = useAllCompetitions();
    // const competitionFilter = useCompetitionFilter();
    //
    // const dispatch = useDispatch();
    //
    // if (competitionFilter === "all") {
    //     if (allCompetitions.length > 0) {
    //         dispatch(setSelectedCompetition(allCompetitions[0]));
    //     }
    //     return matches;
    // }
    //
    // const filterByCompetition = filter<MatchPhone>((match) =>
    //     competitions.includes(match.competition || "")
    // );
    //
    // return filterByCompetition(matches);
};
