export enum AdSize {
    banner = "banner",
    rectangle = "rectangle",
    skyscraper = "skyscraper",
}

export enum AdType {
    direct = "direct",
    amazon = "amazon",
}

export interface AdModel {
    advertiser: string;
    size: AdSize;
    adType: AdType;
    imageUrl?: string;
    iframeUrl?: string;
    campaignName: string;
    creativeName?: string | number;
    targetUrl?: string;
}

interface AmazonAd extends AdModel {
    advertiser: "Amazon";
    size: AdSize;
    adType: AdType.amazon;
    imageUrl: undefined;
    iframeUrl: string;
    campaignName: string;
    creativeName?: string | number;
    targetUrl: undefined;
}

interface DirectAd extends AdModel {
    advertiser: string;
    size: AdSize;
    adType: AdType.direct;
    imageUrl: string;
    iframeUrl: undefined;
    campaignName: string;
    creativeName?: string | number;
    targetUrl: string;
}
