import {
    IonCard,
    IonCol,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import "./Table.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import {
    calculateTeamStats,
    filterResultsInProgressOrPlayed,
    getMatchesForTeam,
    getTeamsInCompetition,
} from "../../services/matchService";
import React, { FC, Fragment, useEffect, useState } from "react";
import { orderBy } from "lodash";
import TableHeader from "./tableHeader";
import { setSelectedCompetition } from "../../redux/actions/competitions";
import TeamLogoComponent from "../../components/TeamLogoComponent";
import AdPlacement from "../../components/ads/AdPlacement";
import { AdSize } from "../../components/ads/AdModel";
import {
    useCompetitionFilter,
    useCompetitions,
    useMatches,
} from "../../contexts/Competition";
import { getMatches } from "../../services/matchesApiService";
import { setMatches } from "../../redux/actions/matches";
import moment from "moment";

interface LeagueRow {
    inProgress: boolean;
    teamName: string;
    gamesPlayed: number;
    points: number;
    goalsScored: number;
    goalsAgainst: number;
    goalDifference: number;
    headToHead: number;
}

const Table: FC = () => {
    const matches = useMatches();

    const competitions = useCompetitions();
    const dispatch = useDispatch();
    const competitionFilter = useCompetitionFilter();
    const selectedCompetition = useSelector((state: RootState) => {
        return state.competitions.selectedCompetition;
    });

    const playedOrInProgress = filterResultsInProgressOrPlayed(matches);

    //const competitions = uniqSort(getCompetitions(playedOrInProgress));

    const [sortedLeagueTable, setSortedLeagueTable] = useState<LeagueRow[]>([]);
    let teams = getTeamsInCompetition(matches, selectedCompetition) as string[];
    const [loading, setLoading] = useState<boolean>(false);

    const generateLeagueTable = (teams: string[]): LeagueRow[] => {
        const newLeagueTable: LeagueRow[] = teams.map((teamName) => {
            const matches = getMatchesForTeam(playedOrInProgress, teamName);
            const { goalsScored, goalsAgainst, points, matchInProgress } =
                calculateTeamStats(matches, teamName);

            return {
                inProgress: matchInProgress,
                teamName: teamName,
                gamesPlayed: getMatchesForTeam(playedOrInProgress, teamName)
                    .length,
                goalsScored: goalsScored,
                goalsAgainst: goalsAgainst,
                points: points,
                goalDifference: goalsScored - goalsAgainst,
                headToHead: 0,
            };
        });
        return newLeagueTable;
    };

    useEffect(() => {
        const fn = async () => {
            setLoading(true);

            const startTs = moment().day(0).month(0).subtract(0, "year");
            const endTs = startTs.clone().add(1, "year");
            const matches = await getMatches(
                competitionFilter,
                startTs.valueOf(),
                endTs.valueOf()
            );
            store.dispatch(setMatches(matches.data));
            setLoading(false);
        };
        fn();
    }, [competitionFilter == "talenstaevne"]);
    useEffect(() => {
        teams = getTeamsInCompetition(matches, selectedCompetition) as string[];
        //setSortedLeagueTable([]);
        const orderedTable = sortTable(generateLeagueTable(teams));
        setSortedLeagueTable(orderedTable);
    }, [selectedCompetition, matches]);

    const handleCompetitionSelected = (comp: string) => {
        dispatch(setSelectedCompetition(comp));
    };

    const sortTable = (table: LeagueRow[]) => {
        const orderedTable = orderBy(
            table,
            ["points", "goalDifference", "goalsScored"],
            ["desc", "desc", "desc"]
        );
        return orderedTable;
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle color="primary">Grassroots Live</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle size="large">Table</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonProgressBar
                    type="indeterminate"
                    hidden={!loading}
                ></IonProgressBar>

                <IonList>
                    <IonItem>
                        <IonSelect
                            color="primary"
                            interface="action-sheet"
                            placeholder={selectedCompetition || "Select league"}
                            onIonChange={(e) =>
                                handleCompetitionSelected(e.detail.value)
                            }
                            defaultValue={selectedCompetition}
                        >
                            {competitions &&
                                competitions.map((comp, index) => {
                                    return (
                                        <IonSelectOption
                                            key={index}
                                            value={comp}
                                        >
                                            {comp}
                                        </IonSelectOption>
                                    );
                                })}
                        </IonSelect>
                    </IonItem>
                </IonList>
                <IonCard color="dark">
                    <TableHeader />
                    <IonList>
                        {sortedLeagueTable.length > 0 &&
                            sortedLeagueTable
                                .map((team, index) => {
                                    return (
                                        <Fragment key={team.teamName}>
                                            <IonItem
                                                lines="full"
                                                color={
                                                    team.inProgress
                                                        ? "medium"
                                                        : "dark"
                                                }
                                            >
                                                <IonCol
                                                    size="1.5"
                                                    className="center"
                                                >
                                                    <IonText>
                                                        {index + 1}
                                                    </IonText>
                                                </IonCol>
                                                <TeamLogoComponent
                                                    teamName={team.teamName}
                                                />
                                                <IonCol size="5">
                                                    <IonText>
                                                        {team.teamName}
                                                    </IonText>
                                                </IonCol>
                                                <IonCol
                                                    size="1"
                                                    className="center"
                                                >
                                                    <IonText>
                                                        {team.gamesPlayed}
                                                    </IonText>
                                                </IonCol>
                                                <IonCol
                                                    size="2"
                                                    className="center"
                                                >
                                                    <IonText>
                                                        {team.goalDifference}
                                                    </IonText>
                                                </IonCol>
                                                <IonCol
                                                    size="1"
                                                    className="center"
                                                >
                                                    <IonText>
                                                        {team.points}
                                                    </IonText>
                                                </IonCol>
                                            </IonItem>
                                            {sortedLeagueTable.length > 4 &&
                                                index ===
                                                    Math.floor(
                                                        (sortedLeagueTable.length -
                                                            1) /
                                                            2
                                                    ) && (
                                                    <IonItem color="dark">
                                                        <AdPlacement
                                                            size={AdSize.banner}
                                                        ></AdPlacement>
                                                    </IonItem>
                                                )}
                                        </Fragment>
                                    );
                                })
                                .sort()}
                    </IonList>
                </IonCard>
                <AdPlacement size={AdSize.rectangle}></AdPlacement>
                {/*<div*/}
                {/*    style={{ display: "flex", justifyContent: "space-around" }}*/}
                {/*>*/}
                {/*    <iframe*/}
                {/*        sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"*/}
                {/*        style={{ width: "120px", height: "240px" }}*/}
                {/*        scrolling="no"*/}
                {/*        frameBorder="0"*/}
                {/*        src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=GB&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=grassrootslive-21&language=en_GB&marketplace=amazon&region=GB&placement=B0B6C9ZFQ8&asins=B0B6C9ZFQ8&linkId=ce0f97fa6f6626ee15b945691c058bc1&show_border=true&link_opens_in_new_window=true"*/}
                {/*    ></iframe>*/}

                {/*    <iframe*/}
                {/*        sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"*/}
                {/*        style={{ width: "120px", height: "240px" }}*/}
                {/*        frameBorder="0"*/}
                {/*        src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=GB&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=grassrootslive-21&language=en_GB&marketplace=amazon&region=GB&placement=B0BDMVKTQ3&asins=B0BDMVKTQ3&linkId=226b1db667c57eb6a384d3f9fa221d0e&show_border=true&link_opens_in_new_window=true"*/}
                {/*    ></iframe>*/}
                {/*</div>*/}
            </IonContent>
        </IonPage>
    );
};

export default Table;
