import React, { FC } from "react";
import { IonText } from "@ionic/react";
import { EventName, HalfEvent, SelectedTeam } from "refsix-js-models";
import mapEventNameToDisplayName from "../utils/MapEventToDisplayName";
import { t } from "i18next";
import { getPlayerIncidentIcon } from "../utils/playersUtils";
import { getStringForEvent, getTeamSide } from "../utils/teamUtils";
import "./components.css";
import "../pages/fixturesSegments/teams.css";
import {
    getKickOffSide,
    halfObject,
    showKickOffSide,
} from "../utils/eventsUtils";
import TimeComponent from "./TimeComponent";
import {
    DisplayEvent,
    DisplayEventType,
} from "../pages/fixturesSegments/match";

interface Event {
    displayEvent: DisplayEvent;
    matchInProgress?: boolean;
}

const MatchLogComponent: FC<Event> = ({ displayEvent, matchInProgress }) => {
    let event = displayEvent.event;

    let isFutureEndHalf =
        displayEvent.type === DisplayEventType.periodEnd &&
        displayEvent.event.eventName === EventName.half &&
        (displayEvent.event as HalfEvent).endTime &&
        // @ts-ignore
        (displayEvent.event as HalfEvent).endTime > Date.now();

    function logDisplay() {
        // hide future end events.
        return (
            <div className="log-half-container">
                <div
                    style={{
                        display: "flex",
                        width: "10%",
                        justifyContent: "left",
                        alignItems: "center",
                    }}
                >
                    {showKickOffSide(event) === 0 && (
                        <div
                            style={{
                                backgroundColor: "white",
                                borderRadius: 99,
                                height: 5,
                                width: 5,
                            }}
                        />
                    )}
                </div>

                <div className="log-middle-wrapper">
                    {!(
                        !displayEvent.period.playing &&
                        displayEvent.event.eventName === EventName.half
                    ) && (
                        <IonText className="log-minute-play-text">
                            {/* pre-formatted minute to display */}
                            {displayEvent.displayMinute}
                        </IonText>
                    )}

                    <IonText className="log-event-name-text">
                        {mapEventNameToDisplayName(event, t)}
                        {displayEvent.type === DisplayEventType.periodStart && (
                            // TODO translate
                            <span> Start</span>
                        )}
                        {displayEvent.type === DisplayEventType.periodEnd && (
                            <span> End</span>
                        )}
                    </IonText>
                    <IonText className="log-timestamp-text">
                        {displayEvent.type === DisplayEventType.periodEnd &&
                            displayEvent.period?.endTime && (
                                <TimeComponent
                                    dateTimeInUTC={displayEvent.period.endTime}
                                />
                            )}
                        {displayEvent.type !== DisplayEventType.periodEnd && (
                            <TimeComponent dateTimeInUTC={event.timestamp} />
                        )}
                    </IonText>
                </div>

                <div
                    style={{
                        display: "flex",
                        width: "10%",
                        justifyContent: "right",
                        alignItems: "center",
                    }}
                >
                    {showKickOffSide(event) === 1 && (
                        <div
                            style={{
                                backgroundColor: "white",
                                borderRadius: 99,
                                height: 5,
                                width: 5,
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    function displayPenaltyShots(side: string) {
        return (
            <div style={{ display: "flex" }}>
                {side === SelectedTeam.home && (
                    <IonText>{`${event.minuteOfPlay}`}</IonText>
                )}
                <div
                    style={{ marginRight: 10 }}
                    className={`event-icons ${getPlayerIncidentIcon(event)}`}
                />
                {side === SelectedTeam.away && (
                    <IonText>{getStringForEvent(event)}</IonText>
                )}
            </div>
        );
    }

    function renderHomeOrAway(side: SelectedTeam) {
        const halfEvent = event as HalfEvent;
        if (halfEvent.name === "Half Time") {
            return <div />;
        }
        let selectedSideIndex = 0;
        if (side === SelectedTeam.away) {
            selectedSideIndex = 1;
        }

        return (getKickOffSide(event) === selectedSideIndex ||
            getTeamSide(event) === side) &&
            halfObject(event) ? (
            <div>
                <div className="match-log-event-name">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {selectedSideIndex === 1 && (
                            <div
                                className={`event-icons ${getPlayerIncidentIcon(
                                    event
                                )}`}
                            />
                        )}
                        <div className="match-log-event-name">
                            <IonText className="player-name-log-text">
                                {getStringForEvent(event)}
                            </IonText>
                            {event.eventName === EventName.substitution && (
                                <IonText className="player-off-text">
                                    {getStringForEvent(event, true)}
                                </IonText>
                            )}
                        </div>
                        {selectedSideIndex === 0 && (
                            <div
                                className={`event-icons ${getPlayerIncidentIcon(
                                    event
                                )}`}
                            />
                        )}
                    </div>
                </div>
            </div>
        ) : getKickOffSide(event) === selectedSideIndex &&
          event.eventName === EventName.half ? (
            <div />
        ) : (
            getTeamSide(event) === side &&
            event.eventName === EventName.penaltyShot &&
            displayPenaltyShots(side)
        );
    }

    if (matchInProgress && isFutureEndHalf) {
        return <></>;
    }

    return (
        <div className="match-log-container">
            <div
                data-testid="logSeparator"
                className="match-log-event-separator-wrapper"
            >
                <div className="match-log-event-separator" />
            </div>

            <div className="card-middle-container">
                <div
                    data-testid="logEventHome"
                    className="team-name-color-container"
                >
                    {renderHomeOrAway(SelectedTeam.home)}
                </div>

                <div>
                    {event.eventName === EventName.half ||
                    event.eventName === EventName.penalties ? (
                        logDisplay()
                    ) : (
                        <div className="match-log-time-container">
                            <IonText>{displayEvent.displayMinute}´</IonText>
                        </div>
                    )}
                </div>

                <div
                    data-testid="logEventAway"
                    className="team-name-color-container-right"
                >
                    {renderHomeOrAway(SelectedTeam.away)}
                </div>
            </div>
        </div>
    );
};

export default MatchLogComponent;
