import { EventName, HalfEvent, MatchEvent } from "refsix-js-models";

function millisToMins(millis: number) {
    return millis / 60000;
}

export function minutePlayed(event: MatchEvent) {
    if (event.eventName === EventName.half) {
        let halfEvent = event as HalfEvent;

        if (
            halfEvent.name.includes("End") &&
            halfEvent.name !== "Half Time End"
        ) {
            let actualDurationMins = halfEvent.minuteOfPlay;
            let scheduledDurationMins = millisToMins(halfEvent.length);

            let additionalTime = actualDurationMins - scheduledDurationMins;

            if (additionalTime > 0) {
                let displayMinute = actualDurationMins.toFixed(0);
                let displayAdditionalTime = additionalTime.toFixed(0);
                return `${displayMinute}' + ${displayAdditionalTime}'`;
                return;
            } else {
                let displayMinute = actualDurationMins.toFixed(0);
                return `${displayMinute}'`;
            }
        } else if (halfEvent.name === "Half Time End") {
            return `${halfEvent.minuteOfPlay.toFixed(0)}´+ ${
                halfEvent.endMinute
            }´`;
        }
    } else if (event.eventName === EventName.penalties) {
        let halfEvent = event as HalfEvent;
        if (halfEvent.name !== undefined && halfEvent.name.includes("End")) {
            return halfEvent.name;
        }
    }
    return `${event.minuteOfPlay}´`;
}

export function halfObject(event: MatchEvent) {
    return (
        event.eventName !== EventName.half &&
        event.eventName !== EventName.penalties
    );
}

export function getKickOffSide(event: MatchEvent) {
    if (event.eventName === EventName.half) {
        let halfEvent = event as HalfEvent;
        return halfEvent.kickOffSide;
    }
}

export function showKickOffSide(event: MatchEvent) {
    if (event.eventName === EventName.half) {
        let halfHalfEvent = event as HalfEvent;
        if (halfHalfEvent.name.includes("End")) {
            return;
        }
    }
    return getKickOffSide(event);
}
