import { useLocation } from "react-router-dom";

import ReactGA from "react-ga4";

/**
 * This component is just a black hole into which all route changes fall.
 */
export function PageViewAnalytics() {
    const trackingId = process.env.REACT_APP_GA_ID;
    if (trackingId) {
        ReactGA.initialize([
            {
                trackingId,
                gtagOptions: {},
            },
        ]);
    } else {
    }

    const location = useLocation();
    // after the first time this fires, the location is wrapped in another object, hence location.location
    if (location) {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
    return null;
}
