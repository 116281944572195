import { EventName, HalfEvent, MatchPhone } from "refsix-js-models";
import { FC, useEffect, useState } from "react";
import { t } from "i18next";
import { processEvents } from "refsix-core";

interface LiveMatchTimeInput {
    match: MatchPhone;
}

export const LiveMatchTime: FC<LiveMatchTimeInput> = ({ match }) => {
    let intervalTimer: any;
    const [timeString, setTimeString] = useState<string>(calculateTime(match));

    function calculateTime(theMatch: MatchPhone): string {
        if (theMatch.matchEvents && !theMatch.eventsProcessed) {
            theMatch = processEvents(theMatch);
        }

        if (!theMatch.matchEvents) {
            return "";
        }

        const periods = Object.values(theMatch.matchEvents).filter((event) => {
            return event.eventName === EventName.half;
        }) as HalfEvent[];

        if (!periods.length) {
            return "";
        }
        const currentPeriod = periods[periods.length - 1];

        if (!currentPeriod.playing) {
            return t("performance.VideoPlayer.halfTime");
        }

        const now = Date.now();

        const diff = now - currentPeriod.timestamp;
        return secondsToMinuteClock(currentPeriod.minuteOfPlay, diff / 1000);
    }

    function secondsToMinuteClock(startMinute: number, secs: number) {
        var minutes = Math.floor(secs / 60);
        var seconds = Math.round(secs - minutes * 60);
        const gameMins = startMinute + minutes;
        var displayMinutes =
            gameMins > 99 ? gameMins : ("0" + gameMins).slice(-2);
        return displayMinutes + ":" + ("0" + seconds).slice(-2);
    }

    useEffect(() => {
        if (match.matchFinished) {
            if (intervalTimer) {
                clearInterval(intervalTimer);
            }
            return;
        }
        intervalTimer = setInterval(() => {
            setTimeString(calculateTime(match));
        }, 1000);

        return () => {
            if (intervalTimer) {
                clearInterval(intervalTimer);
            }
        };
    }, [match]);
    return <span>{timeString}</span>;
};
