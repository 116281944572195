import { FC, useEffect, useState } from "react";
import { IonCol, IonText } from "@ionic/react";
import { makeShortName } from "refsix-core";
import "../style/general.css";
interface TeamLogoProps {
    teamName: string;
    largeIcon?: boolean;
}

const TeamLogoComponent: FC<TeamLogoProps> = ({
    teamName,
    largeIcon = false,
}) => {
    const [teamsHasLogo, setTeamsHasLogo] = useState<boolean>(false);

    const trimmedTeamName = teamName.replaceAll(" ", "-").toLowerCase();
    const imageUrl = `https://assets.grassrootslive.app/team-logos/${trimmedTeamName}.png`;

    useEffect(() => {
        setTeamsHasLogo(false);
    }, [teamName]);

    return (
        <div className={teamsHasLogo ? "has-logo container" : "container"}>
            <img
                className={
                    largeIcon
                        ? "logo-image large-logo"
                        : "logo-image small-logo"
                }
                src={imageUrl}
                style={{
                    border: "2px solid white",
                    borderRadius: "100%",
                    background: "white",
                }}
                onLoad={() => {
                    setTeamsHasLogo(true);
                }}
                alt=""
            />
            <div
                className={
                    largeIcon
                        ? "default-logo-background large-logo"
                        : "default-logo-background small-logo"
                }
            >
                <IonText className="text-logo-container">
                    {makeShortName(teamName)}
                </IonText>
            </div>
        </div>
    );
};

export default TeamLogoComponent;
