import { FC } from "react";
import { AdSize } from "./AdModel";
import DisplayAd from "./DisplayAd";
import { AdService } from "./AdService";

interface AdPlacementInput {
    size: AdSize;
}

const AdPlacement: FC<AdPlacementInput> = ({ size }) => {
    // TODO reload on time period?
    const ad = AdService.instance.getAd(size);
    if (ad) {
        return <DisplayAd ad={ad}></DisplayAd>;
    }
    return <></>;
};

export default AdPlacement;
