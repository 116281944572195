import { MatchPhone } from "refsix-js-models";
import { FC, useEffect, useState } from "react";
import { IonText } from "@ionic/react";
import { calculateScore } from "../services/matchService";
import { processMatch } from "refsix-core";

export enum ScoreType {
    "home" = "home",
    "away" = "away",
    "both" = "both",
    "homeKFTPM" = "homeKFTPM",
    "awayKFTPM" = "awayKFTPM",
    "bothKFTPM" = "both",
    "homeScoreAndKFTPM" = "homeScoreAndKFTPM",
    "awayScoreAndKFTPM" = "awayScoreAndKFTPM",
    "bothScoreAndKFTPM" = "bothScoreAndKFTPM",
}

interface MatchScoreInput {
    match: MatchPhone;
    scoreType: ScoreType;
    color?: string;
}

const SCORE_CLASS = "score-goal";
const KFTPM_CLASS = "score-kftpm";

/**
 * Produces nice scores for you through the use of a lot of messy looking code.
 * ScoreType.home outputs "2"
 * ScoreType.homeKFTPM outputs "(5)"
 * ScoreType.homeScoreAndKFTPM outputs "(5) 2"
 * ScoreType.bothScoreAndKFTPM outputs "(5) 2 - 2 (4)"
 * @param match The match
 * @param scoreType The type of score you want
 * @param color The hex of the colour you want
 */
export const MatchScore: FC<MatchScoreInput> = ({
    match,
    scoreType,
    color = "primary",
}) => {
    const [score, setScore] = useState<[number, number]>([0, 0]);

    const [penaltiesPlayed, setPenaltiesPlayed] = useState<boolean>(
        !!match.playedPenalties
    );
    const [kftpm, setKftpm] = useState<[number, number]>(
        match.playedPenalties ? [0, 0] : [-1, -1]
    );

    useEffect(() => {
        const pp: boolean = !!match.playedPenalties;
        setPenaltiesPlayed(pp);
        if (match.matchFinished && match.stats) {
            setScore([match.stats.goalsHomeTotal, match.stats.goalsAwayTotal]);
            setKftpm([
                match.stats.penaltyShotHomeScored,
                match.stats.penaltyShotAwayScored,
            ]);
        } else if (match.matchInProgress || match.matchFinished) {
            setScore(calculateScore(match));
            const stats = processMatch(match);
            setKftpm([
                stats.penaltyShotHomeScored,
                stats.penaltyShotAwayScored,
            ]);
        }
    }, [match, scoreType]);

    /**
     * Yes, I know this is madness, but it gives you nice scores like:
     */
    function getJsx(scoreType: ScoreType): any {
        switch (scoreType) {
            case ScoreType.home:
                return <span className={SCORE_CLASS}>{score[0]}</span>;
            case ScoreType.away:
                return <span className={SCORE_CLASS}>{score[1]}</span>;
            case ScoreType.both:
                return (
                    <>
                        {getJsx(ScoreType.home)} - {getJsx(ScoreType.away)}
                    </>
                );
            case ScoreType.homeKFTPM:
                return penaltiesPlayed ? (
                    <span className={KFTPM_CLASS}>({kftpm[0]})</span>
                ) : (
                    <></>
                );
            case ScoreType.awayKFTPM:
                return penaltiesPlayed ? (
                    <span className={KFTPM_CLASS}>({kftpm[1]})</span>
                ) : (
                    <></>
                );

            case ScoreType.bothKFTPM:
                return penaltiesPlayed ? (
                    <>
                        {getJsx(ScoreType.homeKFTPM)} -{" "}
                        {getJsx(ScoreType.awayKFTPM)}
                    </>
                ) : (
                    <></>
                );

            case ScoreType.homeScoreAndKFTPM:
                if (penaltiesPlayed) {
                    return (
                        <>
                            {getJsx(ScoreType.homeKFTPM)}{" "}
                            {getJsx(ScoreType.home)}
                        </>
                    );
                } else {
                    return getJsx(ScoreType.home);
                }

            case ScoreType.awayScoreAndKFTPM:
                if (penaltiesPlayed) {
                    return (
                        <>
                            {getJsx(ScoreType.awayKFTPM)}{" "}
                            {getJsx(ScoreType.away)}
                        </>
                    );
                } else {
                    return getJsx(ScoreType.away);
                }

            case ScoreType.bothScoreAndKFTPM:
                if (penaltiesPlayed) {
                    return (
                        <>
                            {getJsx(ScoreType.homeKFTPM)}{" "}
                            {getJsx(ScoreType.home)} - {getJsx(ScoreType.away)}{" "}
                            {getJsx(ScoreType.awayKFTPM)}
                        </>
                    );
                } else {
                    return getJsx(ScoreType.both);
                }
        }
    }

    if (!(match.matchFinished || match.matchInProgress)) {
        return <IonText className="match-score"></IonText>;
    }

    return (
        <IonText className="match-score" style={{ color: color }}>
            {getJsx(scoreType)}
        </IonText>
    );
};
