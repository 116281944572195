import {
    SET_COMPETITION_FILTER,
    SET_COMPETITIONS,
    SET_SELECTED_COMPETITION,
} from "../actions/types";
import { Competitions } from "../AppState";

// TODO change in the future
const initialState: Competitions = {
    selectedCompetition: "Sunday Football League - London",
    competitionFilter: "all",
    competitions: [],
};

function competitionReducer(state: Competitions = initialState, action: any) {
    switch (action.type) {
        case SET_SELECTED_COMPETITION:
            return {
                ...state,
                selectedCompetition: action.competition,
            };
        case SET_COMPETITION_FILTER:
            return {
                ...state,
                competitionFilter: action.competition,
            };
        case SET_COMPETITIONS:
            return {
                ...state,
                competitions: action.competitions,
            };
    }
    return state || initialState;
}

export default competitionReducer;
