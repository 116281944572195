import { IonText } from "@ionic/react";
import { MatchPhone } from "refsix-js-models";
import { FC } from "react";
import "../pages/fixtures.css";
import "./LiveButton.css";
import TimeComponent from "./TimeComponent";
import { LiveMatchTime } from "./LiveMatchTime";
import moment from "moment";
import TeamRowComponent from "./TeamRowComponent";
import { ScoreType } from "./MatchScore";
import "./MatchUpperCardComponent.css";
import { MatchStatus } from "./MatchStatus";

interface Match {
    match: MatchPhone;
}

const MatchUpperCardComponent: FC<Match> = ({ match }) => {
    return (
        <div>
            <div color="transparent" className="match-top-row" style={{}}>
                <div>
                    <IonText color="secondary">
                        {`${moment(match.date).format("ddd ll")} `}
                        <TimeComponent dateTimeInUTC={match.date} />
                    </IonText>
                </div>

                <div>
                    {match.matchInProgress && !match.matchFinished && (
                        <div
                            className="live-indicator"
                            style={{ minWidth: "100px" }}
                        >
                            Live&nbsp;
                            <LiveMatchTime match={match}></LiveMatchTime>
                        </div>
                    )}

                    <MatchStatus match={match} />
                </div>
            </div>

            <TeamRowComponent
                match={match}
                teamName={match.homeTeam}
                scoreType={ScoreType.homeScoreAndKFTPM}
            />
            <TeamRowComponent
                match={match}
                teamName={match.awayTeam}
                scoreType={ScoreType.awayScoreAndKFTPM}
            />

            <div className="separator" />
        </div>
    );
};

export default MatchUpperCardComponent;
