import {
    SET_COMPETITION_FILTER,
    SET_COMPETITIONS,
    SET_SELECTED_COMPETITION,
} from "./types";

export const setSelectedCompetition = (competition: string) => ({
    type: SET_SELECTED_COMPETITION,
    competition,
});

export const setCompetitionFilter = (competition: string) => ({
    type: SET_COMPETITION_FILTER,
    competition,
});

export const setCompetitions = (competitions: string[]) => ({
    type: SET_COMPETITIONS,
    competitions,
});
