import {
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCol,
    IonContent,
    IonHeader,
    IonItem,
    IonPage,
    IonProgressBar,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Teams from "./teams";
import { useTranslation } from "react-i18next";
import Match from "./match";
import "./fixturesSegments.css";
import { MatchScore, ScoreType } from "../../components/MatchScore";
import { LiveMatchTime } from "../../components/LiveMatchTime";
import TeamLogoComponent from "../../components/TeamLogoComponent";
import "../../style/general.css";
import moment from "moment";
import AdPlacement from "../../components/ads/AdPlacement";
import { AdSize } from "../../components/ads/AdModel";
import TimeComponent from "../../components/TimeComponent";
import { useCompetitionFilter, useMatches } from "../../contexts/Competition";
import { getMatch } from "../../services/matchesApiService";
import { setMatches } from "../../redux/actions/matches";
import { useDispatch } from "react-redux";

function FixturesSegments() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const competition = useCompetitionFilter();
    const dispatch = useDispatch();

    const [showLoading, setShowLoading] = useState<boolean>(false);

    const matches = useMatches();
    const match = matches.find((match) => {
        if (match._id === id) {
            return match;
        }
    });
    const [selectedValue, setSelectedValue] = useState<string>("match");

    useEffect(() => {
        if (id && !match) {
            setShowLoading(true);
            getMatch(id)
                .then((response) => {
                    setShowLoading(false);
                    if (response.data) {
                        dispatch(setMatches(response.data));
                    }
                })
                .catch((error) => {
                    setShowLoading(false);
                });
        }
    }, [id, match]);

    if (!match) {
        return (
            <IonPage>
                <IonContent>
                    <IonHeader className="ion-header-overview">
                        <IonToolbar className="ion-toolbar" color="toolbar">
                            <IonButtons slot="start">
                                <IonBackButton
                                    defaultHref={`/${competition}`}
                                    color="primary"
                                    data-testid="match-details-back"
                                />
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonProgressBar type="indeterminate" />
                    <IonCard>
                        <IonCardHeader>
                            <IonText data-testid="matchNotExist">
                                Match not found
                            </IonText>
                        </IonCardHeader>
                    </IonCard>
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage>
            <IonContent fullscreen scrollY={false}>
                <IonHeader className="ion-header-overview">
                    <IonToolbar className="ion-toolbar" color="toolbar">
                        <IonTitle
                            style={{ textAlign: "center" }}
                            data-testid="match-details-title"
                        >
                            {t("general.matchDetails")}
                        </IonTitle>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref={`/${competition}`}
                                color="primary"
                                data-testid="match-details-back"
                            />
                        </IonButtons>
                        {match.matchInProgress && !match.matchFinished && (
                            <IonText
                                color="primary"
                                slot="end"
                                style={{ marginRight: "10px" }}
                            >
                                Live
                            </IonText>
                        )}
                    </IonToolbar>
                </IonHeader>
                <IonCard color="dark">
                    <IonItem lines="none" color="transparent">
                        <IonCol className="container">
                            <IonText className="sub-title">
                                {moment(match.date).isSame(new Date(), "day")
                                    ? t("fixture.Fixture.today")
                                    : moment(match.date).format("ddd ll")}
                                &nbsp;
                                <TimeComponent
                                    dateTimeInUTC={match.date}
                                ></TimeComponent>
                            </IonText>
                        </IonCol>
                    </IonItem>
                    <IonItem lines="none" color="transparent">
                        <IonCol
                            size={
                                match.matchFinished || match.matchInProgress
                                    ? "4"
                                    : "5.5"
                            }
                        >
                            <TeamLogoComponent
                                teamName={match.homeTeam}
                                largeIcon={true}
                            />

                            <IonText className="center text-align-center text-small">
                                {match.homeTeam}
                            </IonText>
                        </IonCol>
                        {!(match.matchFinished || match.matchInProgress) && (
                            <IonCol size="1">
                                <IonText className="center text-align-center text-small">
                                    -
                                </IonText>
                            </IonCol>
                        )}

                        {(match.matchFinished || match.matchInProgress) && (
                            <IonCol
                                size="4"
                                className="container"
                                data-testid="scoresSection"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {match.stats?.playedET[0] !== 0 &&
                                    match.stats?.playedPenalties[0] === 0 && (
                                        <div className="penalties-extra-time-container score-formatting">
                                            <IonText
                                                className="penalties-extra-time-text"
                                                data-testid="penalties-extraTime-text"
                                            >
                                                {t(
                                                    "fixture.FixtureSummary.atExtraTime"
                                                )}
                                            </IonText>
                                        </div>
                                    )}
                                {match.stats?.playedPenalties[0] === 1 && (
                                    <div className="penalties-extra-time-container score-formatting">
                                        <IonText
                                            className="penalties-extra-time-text"
                                            data-testid="penalties-extraTime-text"
                                        >
                                            {t(
                                                "fixture.FixtureSummary.atPenalties"
                                            )}
                                        </IonText>
                                    </div>
                                )}

                                <IonRow
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <IonText
                                        style={{
                                            fontSize: 30,
                                            color: "white",
                                            marginRight: 5,
                                        }}
                                    >
                                        <MatchScore
                                            match={match}
                                            scoreType={ScoreType.home}
                                            color="white"
                                        />
                                    </IonText>
                                    {!match.matchInProgress && (
                                        <IonCol className="match-finished-score-text-container">
                                            <IonText>
                                                {t(
                                                    "performance.VideoPlayer.fullTime"
                                                )}
                                            </IonText>
                                        </IonCol>
                                    )}
                                    {match.matchInProgress && (
                                        <IonRow
                                            data-testid="liveTime"
                                            class="live-indicator"
                                        >
                                            <div style={{ minWidth: "50px" }}>
                                                <LiveMatchTime match={match} />
                                            </div>
                                        </IonRow>
                                    )}
                                    <IonText
                                        style={{
                                            fontSize: 30,
                                            color: "white",
                                            marginLeft: 5,
                                        }}
                                    >
                                        <MatchScore
                                            match={match}
                                            scoreType={ScoreType.away}
                                            color="white"
                                        />
                                    </IonText>
                                </IonRow>

                                {match.stats &&
                                    (match.stats.penaltyShotHomeScored > 0 ||
                                        match.stats.penaltyShotAwayScored >
                                            0) && (
                                        <IonRow className="container">
                                            <IonText>
                                                {
                                                    match.stats
                                                        ?.penaltyShotHomeScored
                                                }
                                            </IonText>
                                            <IonText>-</IonText>
                                            <IonText>
                                                {
                                                    match.stats
                                                        ?.penaltyShotAwayScored
                                                }
                                            </IonText>
                                        </IonRow>
                                    )}
                            </IonCol>
                        )}

                        <IonCol
                            size={
                                match.matchFinished || match.matchInProgress
                                    ? "4"
                                    : "5.5"
                            }
                        >
                            <TeamLogoComponent
                                teamName={match.awayTeam}
                                largeIcon={true}
                            />
                            <IonText className="center text-align-center text-small">
                                {match.awayTeam}
                            </IonText>
                        </IonCol>
                    </IonItem>
                    <IonItem lines="none" color="transparent">
                        <IonCol className="container">
                            <IonText className="sub-title">
                                {match.venue}
                            </IonText>
                        </IonCol>
                    </IonItem>
                </IonCard>

                <AdPlacement size={AdSize.banner}></AdPlacement>

                {/* SEGMENT BUTTONS*/}
                <IonItem className="item-segments-container">
                    <IonSegment
                        scrollable
                        value={selectedValue}
                        className="segment"
                        mode="ios"
                        onIonChange={(e) =>
                            e.detail.value !== undefined &&
                            setSelectedValue(e.detail.value)
                        }
                    >
                        <IonSegmentButton
                            value="match"
                            className="segment-button"
                        >
                            {t("general.match")}
                        </IonSegmentButton>
                        <IonSegmentButton
                            value="teams"
                            className="segment-button"
                        >
                            {t("general.teams")}
                        </IonSegmentButton>
                    </IonSegment>
                </IonItem>

                {selectedValue === "teams" && <Teams match={match} />}
                {selectedValue === "match" && <Match match={match} />}

                {/*{showToast && (*/}
                {/*    <ToastComponent*/}
                {/*        isOpen={showToast}*/}
                {/*        position="bottom"*/}
                {/*        message={toastTitle}*/}
                {/*        onDismiss={() => setShowToast(false)}*/}
                {/*    />*/}
                {/*)}*/}
            </IonContent>
        </IonPage>
    );
}

export default FixturesSegments;
