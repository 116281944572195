import React, { useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import "./DayPicker.css";
import {
    IonButton,
    IonCol,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonModal,
    IonRow,
    useIonRouter,
} from "@ionic/react";
import { calendar, chevronBack, chevronForward, trophy } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/AppState";
import { setSelectedDay } from "../redux/actions/selectedDay";
import { DatetimeChangeEventDetail } from "@ionic/core/dist/types/components/datetime/datetime-interface";
import { IonDatetimeCustomEvent } from "@ionic/core/dist/types/components";
import {useCompetitionFilter} from "../contexts/Competition";

export const useSelectedDay = () =>
  useSelector(
    (state: AppState) => state.selectedDay.selectedDay
  );

function DayPicker() {
    const dispatch = useDispatch();
    const selectedDay = useSelectedDay();
    const competitionFilter = useCompetitionFilter();
    const [currentWeekStart, setCurrentWeekStart] = useState<Moment>(
        moment().startOf("isoWeek")
    );
    const router = useIonRouter()
    const calendarEl = useRef<HTMLIonDatetimeElement>(null);
    const calendarModalEl = useRef<HTMLIonModalElement>(null);
    const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

    const generateWeek = (dayInWeek: Moment) => {
        const startOfWeek = dayInWeek.clone().startOf("isoWeek"); // Get the start of the current week
        const endOfWeek = dayInWeek.clone().endOf("isoWeek"); // Get the end of the current week

        const daysOfWeek = [];

        // Loop from the start to the end of the week and add each day to the list
        for (
            let current = startOfWeek;
            current <= endOfWeek;
            current = current.clone().add(1, "day")
        ) {
            daysOfWeek.push(current); // Format the day as desired (e.g., 'dddd' for the full day name)
        }

        return daysOfWeek;
    };

    const selectWeek = (startOfWeek: Moment) => {
        // double check what we are given
        const start = startOfWeek.clone().startOf("isoWeek");
        setCurrentWeekStart(start);
        setDaysOfCurrentWeek(generateWeek(start));
    };

    const selectDay = (day: Moment) => {
        dispatch(setSelectedDay(day.clone().startOf("day")));
        selectWeek(day);
    };

    useEffect(() => {
        if (selectedDay) {
            selectWeek(selectedDay);
        }
    }, [selectedDay]);

    const nextWeek = () => {
        const nextWeek = currentWeekStart.clone().add(1, "week");
        selectWeek(nextWeek);
    };

    const prevWeek = () => {
        const prevWeek = currentWeekStart.clone().subtract(1, "week");
        selectWeek(prevWeek);
    };

    const selectToday = () => {
        selectDay(moment());
    };

    const selectCalendarDay = (
        event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
    ) => {
        if (!event || !event.detail) return;
        const dateString = event.detail.value;
        const dateObj = moment(dateString);

        dispatch(setSelectedDay(dateObj));
        calendarEl && calendarEl.current && calendarEl.current.cancel(true);
        // setCalendarModalOpen(false);
    };

    const [daysOfCurrentWeek, setDaysOfCurrentWeek] = useState<Moment[]>(
        generateWeek(moment())
    );

    const makeCalendarDateString = (date: Moment): string => {
        return date.clone().toISOString(true);
    };

    if (!selectedDay) {
        return <></>;
    }

    return (
        <div className={"day-picker"}>
            <IonGrid>
                <IonRow className={"day-picker-header"}>
                    <IonCol size="2">
                        <IonButton
                            className={"header-button"}
                            style={
                                selectedDay.isSame(moment(), "day")
                                    ? { opacity: 0 }
                                    : {}
                            }
                            onClick={selectToday}
                            size={"small"}
                        >
                            Today
                        </IonButton>
                    </IonCol>
                    <IonCol size="8">
                        <h1 className={"grl-heading"}>Grassroots Live</h1>
                    </IonCol>
                    <IonCol size="2" style={{ textAlign: "right" }}>
                        {competitionFilter === "talenstaevne" ? (<IonButton href={`/${competitionFilter}/table`} className={"header-button calendar-button"}
                                  size={"small"} onClick={() => {
                                      router.push(`/${competitionFilter}/table`)
                        }}>
                            <IonIcon icon={trophy}></IonIcon>
                        </IonButton>) : null }
                        <IonButton
                            className={"header-button calendar-button"}
                            size={"small"}
                            onClick={() => {
                                calendarEl.current &&
                                    calendarEl.current.reset(
                                        makeCalendarDateString(selectedDay)
                                    );
                                calendarModalEl.current &&
                                    calendarModalEl.current.present();
                            }}
                        >
                            <IonIcon icon={calendar}></IonIcon>
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <div>
                <IonModal
                    keepContentsMounted={true}
                    ref={calendarModalEl}
                    isOpen={calendarModalOpen}
                    className={"ion-datetime-button-overlay"}
                >
                    <IonDatetime
                        ref={calendarEl}
                        id="datetime"
                        value={makeCalendarDateString(selectedDay)}
                        presentation={"date"}
                        firstDayOfWeek={1}
                        onIonChange={selectCalendarDay}
                        showDefaultButtons={false}
                    ></IonDatetime>
                </IonModal>
            </div>

            <div>
                <div className={"day-buttons"}>
                    <button
                        className={"next-prev-button"}
                        onClick={() => {
                            prevWeek();
                        }}
                    >
                        <IonIcon icon={chevronBack}></IonIcon>
                    </button>
                    {daysOfCurrentWeek.map((day) => (
                        <button
                            key={day.format("YYYY-MM-DD")}
                            className={
                                selectedDay.isSame(day, "day")
                                    ? "day-button selected"
                                    : "day-button"
                            }
                            onClick={() => {
                                dispatch(setSelectedDay(day));
                            }}
                        >
                            <span className={"day-button-name"}>
                                {day.format("ddd")}
                            </span>
                            <span className={"day-button-number"}>
                                {day.format("D MMM")}
                            </span>
                        </button>
                    ))}
                    <button
                        className={"next-prev-button"}
                        onClick={() => {
                            nextWeek();
                        }}
                    >
                        <IonIcon icon={chevronForward}></IonIcon>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DayPicker;
