import { store } from "../redux/store";
import { setMatches } from "../redux/actions/matches";
import axios from "axios";

export function listenForMatchUpdates(
    competitionGroup: string,
    startTs: number,
    endTs: number
): EventSource {
    const eventSource = new EventSource(
        `${process.env.REACT_APP_REFSIX_API_URL}/tracked-matches/sse/${competitionGroup}/${startTs}/${endTs}`
    );
    eventSource.onopen = () => {
        console.log("Live update connection opened");
    };
    eventSource.onerror = (e) => {
        console.log("Live update connection failed");
    };

    eventSource.onmessage = ({ data }) => {
        if (data === "ping") return;
        const dataJson = JSON.parse(data);
        store.dispatch(setMatches([dataJson]));
    };

    return eventSource;
}

export async function getMatches(
    competitionGroup: string,
    startTs: number,
    endTs: number
) {
    return axios.get(
        `${process.env.REACT_APP_REFSIX_API_URL}/tracked-matches/nonsse/${competitionGroup}/${startTs}/${endTs}`
    );
}

export async function getMatch(matchId: string) {
    return axios.get(
        `${process.env.REACT_APP_REFSIX_API_URL}/tracked-matches/match/${matchId}`
    );
}

export async function getCompetitionGroup(competitionGroup: string) {
    return axios.get(
        `${process.env.REACT_APP_REFSIX_API_URL}/grl/groups/${competitionGroup}`
    );
}
