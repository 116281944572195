import { FC } from "react";
import { formatTime } from "refsix-core";

interface TimeProps {
    dateTimeInUTC: string | number;
}

const Time: FC<TimeProps> = ({ dateTimeInUTC }) => {
    const use12HourTime = false; // TODO take time from locale?

    return <>{formatTime(dateTimeInUTC, use12HourTime)}</>;
};

export default Time;
