import { IonGrid, IonRow, IonText } from "@ionic/react";
import { MatchPhone } from "refsix-js-models";
import { FC, useEffect, useState } from "react";
import { RootState } from "../redux/store";
import { useTranslation } from "react-i18next";
import { filterMatches } from "../services/matchService";
import { useSelector } from "react-redux";
import { useMatches } from "../contexts/Competition";

type DaysHoursMins = {
    days: number;
    hours: number;
    mins: number;
};

export function getMinDateFromMatches(matches: MatchPhone[]): Date {
    const sortedMatches = matches
        .filter((match) => !!match.date)
        .sort(
            (matchA, matchB) =>
                new Date(matchA.date as string).getTime() -
                new Date(matchB.date as string).getTime()
        );

    return sortedMatches.length
        ? new Date(sortedMatches[0].date as string)
        : new Date();
}

export function getCountDownFromDate(
    futureDate: Date,
    now: Date
): DaysHoursMins {
    const millis = futureDate.getTime() - now.getTime();

    if (millis < 0) {
        return {
            days: 0,
            hours: 0,
            mins: 0,
        };
    }

    return {
        days: Math.floor(millis / 3600000 / 24),
        hours: Math.floor((millis / 3600000) % 24),
        mins: Math.floor((millis / 60000) % 60),
    };
}

interface Match {
    match?: MatchPhone;
}

const NextMatchInComponent: FC<Match> = ({ match }) => {
    const { t } = useTranslation();

    const daysString = t("fixture.FixtureList.days");
    const hoursString = t("fixture.FixtureList.hours");
    const minutesString = t("general.mins");

    const allMatches = useMatches();
    const matches = filterMatches(allMatches);
    const [days, setDays] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);

    useEffect(() => {
        setCounter();

        const intervalID = setInterval(() => {
            setCounter();
        }, 1000 * 60);
        // Tidy up on unmount of this component
        return () => {
            clearInterval(intervalID);
        };
    });

    function setCounter() {
        const units = getCountDownFromDate(
            match !== undefined
                ? new Date(match.date as string)
                : getMinDateFromMatches(matches),
            new Date()
        );
        setDays(units.days);
        setHours(units.hours);
        setMinutes(units.mins);
    }

    return (
        <IonGrid
            hidden={match?.matchFinished}
            data-testid="nextMatchComponent"
            className="next-game-container"
        >
            <IonText className="next-game-title">
                {t(
                    match !== undefined
                        ? "fixture.FixtureList.thisGame"
                        : "fixture.FixtureList.nextGame"
                )}
            </IonText>
            <IonRow className="row-timers">
                <div className="timer-countdown">
                    <IonText className="time-text-style">{days}</IonText>
                    <IonText className="time-text-when" style={{}}>
                        {daysString.charAt(0).toUpperCase() +
                            daysString.slice(1)}
                    </IonText>
                </div>
                <div className="timer-countdown">
                    <IonText className="time-text-style">{hours}</IonText>
                    <IonText className="time-text-when">
                        {hoursString.charAt(0).toUpperCase() +
                            hoursString.slice(1)}
                    </IonText>
                </div>
                <div className="timer-countdown">
                    <IonText className="time-text-style">{minutes}</IonText>
                    <IonText className="time-text-when">
                        {minutesString.charAt(0).toUpperCase() +
                            minutesString.slice(1)}
                    </IonText>
                </div>
            </IonRow>
        </IonGrid>
    );
};

export default NextMatchInComponent;
