import { LOADING_MATCHES, SELECTED_MATCH, SET_MATCHES } from "../actions/types";
import { Matches } from "../AppState";
import { MatchPhone } from "refsix-js-models";
import _ from "lodash";

const initialState = new Matches();

function compareRevs(rev1: string, rev2: string) {
    const rev1Index = parseInt(rev1.split("-")[0]);
    const rev2Index = parseInt(rev2.split("-")[0]);
    // returns true if rev1 number is greater than rev2 number
    return rev1Index > rev2Index;
}

function matchesReducer(state: Matches = initialState, action: any) {
    switch (action.type) {
        case SET_MATCHES:
            // we want to dedupe and smoosh together the new matches with the existing ones
            const updatedMatches = [...state.matches] || [];

            action.matches.forEach((newMatch: MatchPhone) => {
                const existingPos = _.findIndex(updatedMatches, {
                    _id: newMatch._id as string,
                });
                // if match already exists
                if (existingPos !== -1) {
                    // check rev
                    const winningMatch = compareRevs(
                        updatedMatches[existingPos]._rev as string,
                        newMatch._rev as string
                    )
                        ? updatedMatches[existingPos]
                        : newMatch;
                    updatedMatches[existingPos] = winningMatch;
                } else {
                    updatedMatches.push(newMatch);
                }
            });

            return { ...state, matches: updatedMatches };
        case SELECTED_MATCH:
            return { ...state, match: action.match };
        case LOADING_MATCHES:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
}

export default matchesReducer;
