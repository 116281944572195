import React, { FC, Fragment, useEffect, useState } from "react";
import "./match.css";
import MatchLogComponent from "../../components/MatchLogComponent";
import { EventName, HalfEvent, MatchEvent, MatchPhone } from "refsix-js-models";
import ScrollableContent from "../../components/ScrollableContents";
import { MatchInput } from "./matchInput";
import { millisToMins, processEvents } from "refsix-core";
import NextMatchInComponent from "../../components/NextMatchInComponent";
import AdPlacement from "../../components/ads/AdPlacement";
import { AdSize } from "../../components/ads/AdModel";

export interface halfEndEvent extends HalfEvent {
    displayMinute: string;
}

export enum DisplayEventType {
    periodStart = "periodStart",
    periodEnd = "periodEnd",
    other = "other",
}

export interface DisplayEvent {
    event: MatchEvent;
    displayMinute: string;
    type: DisplayEventType;
    period: HalfEvent;
    sortingTs: number;
}

interface ComputedHalfInfo {
    startMinute: number; // the minute the half started e.g. 45mins
    actualEndMinute: number; // the minute the half ended with additional time subtracted
    additionalTime: number; // the additional time
    scheduledDurationMinutes: number;
    scheduledEndMinute: number;
    actualDurationMinutes: number;
    playing: boolean;
}

interface IndexedMap {
    [index: number]: ComputedHalfInfo;
}

const Match: FC<MatchInput> = ({ match }) => {
    const [displayEvents, setDisplayEvents] = useState<DisplayEvent[]>([]);

    useEffect(() => {
        if (match.matchEvents !== undefined) {
            let eventsArray = match.matchEvents
                ? Object.values(match.matchEvents)
                : [];

            if (!match.eventsProcessed) {
                const processedMatch: MatchPhone = processEvents(match);
                if (processedMatch.matchEvents) {
                    eventsArray = Object.values(processedMatch.matchEvents);
                }
            }

            // let playingPeriods = getPlayingSegments(match);

            let periodsAndIntervals = eventsArray.filter((event) => {
                return (
                    event.eventName === EventName.half ||
                    event.eventName === EventName.penalties
                );
            }) as HalfEvent[];

            let playingPeriodInfo: IndexedMap =
                computeHalfInfo(periodsAndIntervals);

            setDisplayEvents(
                generateDisplayEvents(eventsArray, playingPeriodInfo)
            );
        }
    }, [match.matchEvents]);
    // fix for deleting the match
    if (!match || !displayEvents) {
        console.log("No match or display events");
        return <div />;
    }

    function computeHalfInfo(playingPeriods: HalfEvent[]): IndexedMap {
        const output: IndexedMap = {};
        playingPeriods.forEach((halfEvent, index) => {
            // if endTime not set, default to assume full scheduled half length
            let endTs =
                halfEvent.endTime || halfEvent.timestamp + halfEvent.length;

            let startMinute: number = halfEvent.minuteOfPlay;

            let scheduledDurationMins = millisToMins(halfEvent.length);

            let actualDurationMins = millisToMins(endTs - halfEvent.timestamp);

            let additionalTime = Math.max(
                actualDurationMins - scheduledDurationMins,
                0
            );

            let endMinute =
                additionalTime > 0
                    ? startMinute + scheduledDurationMins
                    : startMinute + actualDurationMins;

            output[halfEvent.index] = {
                startMinute: startMinute,
                actualEndMinute: endMinute,
                scheduledDurationMinutes: scheduledDurationMins,
                actualDurationMinutes: actualDurationMins,
                additionalTime: additionalTime,
                scheduledEndMinute: startMinute + scheduledDurationMins,
                playing: halfEvent.playing,
            };
        });
        return output;
    }

    function generateMinuteString(
        event: MatchEvent,
        currentHalfInfo: ComputedHalfInfo
    ) {
        if (event.minuteOfPlay > currentHalfInfo.actualEndMinute) {
            let displayMinute = currentHalfInfo.actualEndMinute.toFixed(0);
            let displayAdditionalTime =
                event.minuteOfPlay -
                currentHalfInfo.startMinute -
                currentHalfInfo.scheduledDurationMinutes;

            return `${displayMinute}' + ${displayAdditionalTime}'`;
        }
        return `${event.minuteOfPlay}'`;
    }

    function generateDisplayEvents(
        events: MatchEvent[],
        playingPeriodInfo: IndexedMap
    ): DisplayEvent[] {
        let output: DisplayEvent[] = [];
        let sortedEvents = events.sort((a, b) => {
            return a.timestamp - b.timestamp;
        });

        let currentPeriod: HalfEvent;
        let currentPeriodInfo: ComputedHalfInfo;

        sortedEvents.forEach((matchEvent) => {
            // TODO calculate extra time component

            if (
                matchEvent.eventName === EventName.half ||
                matchEvent.eventName === EventName.penalties
            ) {
                currentPeriod = matchEvent as HalfEvent;
                currentPeriodInfo = playingPeriodInfo[currentPeriod.index];
                let endTs =
                    currentPeriod.endTime ||
                    currentPeriod.timestamp + currentPeriod.length;
                // period start
                output.push({
                    event: matchEvent,
                    displayMinute: generateMinuteString(
                        matchEvent,
                        currentPeriodInfo
                    ),
                    type: DisplayEventType.periodStart,
                    period: currentPeriod,
                    sortingTs: matchEvent.timestamp,
                });

                // period end

                const endDisplayMinute =
                    currentPeriodInfo.additionalTime > 0
                        ? `${currentPeriodInfo.actualEndMinute}'+${currentPeriodInfo.additionalTime}'`
                        : `${currentPeriodInfo.actualEndMinute}'`;

                output.push({
                    event: matchEvent,
                    displayMinute: endDisplayMinute,
                    type: DisplayEventType.periodEnd,
                    period: currentPeriod,
                    sortingTs: endTs,
                });
            } else if (currentPeriodInfo) {
                output.push({
                    event: matchEvent,
                    displayMinute: matchEvent.additionalTime
                        ? `${matchEvent.minuteOfPlay}'+${matchEvent.additionalTime}'`
                        : `${matchEvent.minuteOfPlay}'`,
                    type: DisplayEventType.other,
                    period: currentPeriod,
                    sortingTs: matchEvent.timestamp,
                });
            } else {
                console.log(
                    `Event happened before the match kicked off so not displaying it ${matchEvent.timestamp}, ${matchEvent.eventName}`
                );
            }
        });
        return output;
    }

    if (!(match.matchFinished || match.matchInProgress)) {
        return (
            <div>
                <div>
                    <NextMatchInComponent match={match} />
                </div>
                <div>
                    <AdPlacement size={AdSize.rectangle}></AdPlacement>
                </div>
            </div>
        );
    }

    return (
        <ScrollableContent>
            {/*<pre>{JSON.stringify(playingPeriodInfo, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(displayEvents, null, 4)}</pre>*/}

            {displayEvents
                .sort((a, b) => {
                    return b.sortingTs - a.sortingTs;
                })
                .map((displayEvent, index) => {
                    return (
                        <Fragment key={index}>
                            <MatchLogComponent
                                displayEvent={displayEvent}
                                matchInProgress={match.matchInProgress}
                            />
                            {displayEvent.type === DisplayEventType.periodEnd &&
                                !(displayEvent.event as HalfEvent).playing && (
                                    <AdPlacement
                                        size={AdSize.rectangle}
                                    ></AdPlacement>
                                )}
                        </Fragment>
                    );
                })}
            <AdPlacement size={AdSize.rectangle}></AdPlacement>
        </ScrollableContent>
    );
};

export default Match;
