import {
    GoalEvent,
    IncidentEvent,
    MatchEvent,
    MatchPhone,
    PenaltyShotEvent,
    Player,
    SelectedTeam,
    SubstitutionEvent,
} from "refsix-js-models";
import i18n from "react-i18next";

export function getAllPlayerForATeam(
    match: MatchPhone,
    side: SelectedTeam
): Player[] {
    let players: Player[];
    if (side === "home") {
        players =
            match.players !== undefined ? match.players[match.homeTeam] : [];
    } else {
        players =
            match.players !== undefined ? match.players[match.awayTeam] : [];
    }

    return players;
}

export function getAllPlayersIn(players: Player[]): Player[] {
    const starting: Player[] = [];

    if (players !== undefined) {
        players.map((player) => {
            if (player.starting && !player.teamOfficial) {
                starting.push(player);
            }
        });
    }

    return starting;
}

export function getAllSubs(players: Player[]): Player[] {
    const subs: Player[] = [];

    if (players !== undefined) {
        players.map((player) => {
            if (!player.starting && !player.teamOfficial) {
                subs.push(player);
            }
        });
    }

    return subs;
}

export function getAllTeamOfficials(players: Player[]): Player[] {
    const officials: Player[] = [];

    if (players !== undefined) {
        players.map((player) => {
            if (player.teamOfficial) {
                officials.push(player);
            }
        });
    }

    return officials;
}

export default function getPlayerName(player: Player, t: i18n.TFunction) {
    return player.name
        ? player.name
        : t("fixture.FixtureTeam.player") + " " + player.number;
}

export function getPlayerIncidentIcon(event: MatchEvent) {
    switch (event.eventName) {
        case "Goal":
            let goalEvent = event as GoalEvent;

            if (goalEvent.ownGoal) {
                return "ownGoal";
            }

            return "goal";
        case "Incident":
            let incidentEvent = event as IncidentEvent;
            if (incidentEvent.card === "yellow") {
                return "yellow";
            } else if (incidentEvent.card === "red") {
                return "red";
            }

            return "";
        case "Substitution":
            let subsEvent = event as SubstitutionEvent;

            if (subsEvent.playerOn) {
                return "sub";
            }

            return "sub";
        case "PenaltyShot":
            let penaltyShotEvent = event as PenaltyShotEvent;
            if (penaltyShotEvent.goal) {
                return "penaltyShotScored";
            }

            return "penaltyShotMissed";
    }
}

export function generatePlayersEventsStructure(fixture: MatchPhone) {
    let playersWithEvents = fixture.players;

    if (fixture.matchEvents) {
        let orderedEvents = Object.keys(fixture.matchEvents).sort();
        for (let index in orderedEvents) {
            let timestamp = orderedEvents[index];
            if (fixture.matchEvents.hasOwnProperty(timestamp)) {
                let event = fixture.matchEvents[timestamp] as MatchEvent;
                switch (event.eventName) {
                    case "Goal":
                        let goalEvent = event as GoalEvent;
                        if (playersWithEvents !== undefined) {
                            if (goalEvent.player) {
                                let teamName = goalEvent.team.teamName;
                                if (goalEvent.ownGoal) {
                                    teamName =
                                        fixture.homeTeam === teamName
                                            ? fixture.awayTeam
                                            : fixture.homeTeam;
                                }
                                let player = playersWithEvents[teamName].find(
                                    findPlayerByNumber(goalEvent.player.number)
                                );
                                addEventToPlayer(player, event, timestamp);
                            }
                        }
                        break;
                    case "Incident":
                        let incidentEvent = event as IncidentEvent;
                        if (playersWithEvents !== undefined) {
                            let player = playersWithEvents[
                                incidentEvent.team.teamName
                            ].find(
                                findPlayerByNumber(incidentEvent.player.number)
                            );
                            addEventToPlayer(player, event, timestamp);
                        }
                        break;
                    case "Substitution":
                        let subsEvent = event as SubstitutionEvent;
                        if (playersWithEvents !== undefined) {
                            let player = playersWithEvents[
                                subsEvent.team.teamName
                            ].find(
                                findPlayerByNumber(subsEvent.playerOn.number)
                            );
                            addEventToPlayer(player, event, timestamp);
                        }
                        break;
                    case "PenaltyShot":
                        let penEvent = event as PenaltyShotEvent;
                        if (playersWithEvents !== undefined) {
                            let player = playersWithEvents[
                                penEvent.team.teamName
                            ].find(findPlayerByNumber(penEvent.player.number));
                            addEventToPlayer(player, event, timestamp);
                        }
                        break;
                }
            }
        }
    }

    return playersWithEvents;
}

export function addEventToPlayer(player: any, event: any, timestamp: any) {
    if (player) {
        player.events = player.events || {};
        return (player.events[timestamp] = event);
    }
}

export function findPlayerByNumber(playerNumber: number) {
    return function (el: any) {
        return el.number === playerNumber;
    };
}

export function isStarting(players: number[], teamSize: number) {
    return players.length < teamSize;
}
