import { SET_SELECTED_DAY } from "../actions/types";
import { SelectedDay } from "../AppState";
import moment, { Moment } from "moment";

const initialState: SelectedDay = {
    selectedDay: moment().startOf("day"),
    startTs: moment().startOf("day").valueOf(),
    endTs: moment().endOf("day").valueOf(),
};

function matchesReducer(state: SelectedDay = initialState, action: any) {
    switch (action.type) {
        case SET_SELECTED_DAY:
            const selectedDay: Moment = action.selectedDay;
            if (!selectedDay) return state;
            return {
                ...state,
                selectedDay: selectedDay,
                startTs: selectedDay.clone().startOf("day").valueOf(),
                endTs: selectedDay.clone().endOf("day").valueOf(),
            };
        default:
            return state;
    }
}

export default matchesReducer;
