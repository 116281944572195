import { MatchPhone } from "refsix-js-models";
import { FC } from "react";
import { IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface MatchStatusInput {
    match: MatchPhone;
}

export const MatchStatus: FC<MatchStatusInput> = ({ match }) => {
    const { t } = useTranslation();
    return (
        <div className="distance-role">
            {match.matchAbandoned && (
                <div className="penalties-extra-time-container">
                    <IonText
                        className="penalties-extra-time-text"
                        data-testid="penalties-extraTime-text"
                    >
                        {t("model.WatchFixture.abandonedDisplayName")}
                    </IonText>
                </div>
            )}
            {(match.playedExtraTime || match.playedPenalties) && (
                <div className="penalties-extra-time-container">
                    <IonText
                        className="penalties-extra-time-text"
                        data-testid="penalties-extraTime-text"
                    >
                        {(match.playedExtraTime && match.playedPenalties) ||
                        match.playedPenalties
                            ? t("fixture.FixtureSummary.atPenalties")
                            : match.playedExtraTime
                            ? t("fixture.FixtureSummary.atExtraTime")
                            : ""}
                    </IonText>
                </div>
            )}
        </div>
    );
};
