import i18n from "react-i18next";
import {
    HalfEvent,
    MatchEvent,
    PenaltiesEvent,
    PeriodNames,
} from "refsix-js-models";

export default function mapEventNameToDisplayName(
    event: MatchEvent,
    t: i18n.TFunction
) {
    let eventName;

    if (event.eventName === "Half") {
        let halfEvent = event as HalfEvent;
        eventName = halfEvent.name ? halfEvent.name : halfEvent.eventName;
    } else if (event.eventName === "Penalties") {
        let penaltiesEvent = event as PenaltiesEvent;
        eventName = penaltiesEvent.eventName;
    } else {
        eventName = event.eventName;
    }

    let displayName: string;

    switch (eventName.trim()) {
        case PeriodNames.firstPeriod:
            displayName = t("model.WatchFixture.firstPeriodDisplayName");
            break;
        case PeriodNames.firstHalf:
            displayName = t("model.WatchFixture.firstHalfDisplayName");
            break;
        case PeriodNames.secondHalf:
            displayName = t("model.WatchFixture.secondHalfDisplayName");
            break;
        case PeriodNames.halfTime:
            displayName = t("model.WatchFixture.halfTimeDisplayName");
            break;
        case PeriodNames.firstThird:
            displayName = t("model.WatchFixture.firstThirdDisplayName");
            break;
        case PeriodNames.secondThird:
            displayName = t("model.WatchFixture.secondThirdDisplayName");
            break;
        case PeriodNames.thirdThird:
            displayName = t("model.WatchFixture.thirdThirdDisplayName");
            break;
        case PeriodNames.firstQuarter:
            displayName = t("model.WatchFixture.firstQuarterDisplayName");
            break;
        case PeriodNames.secondQuarter:
            displayName = t("model.WatchFixture.secondQuarterDisplayName");
            break;
        case PeriodNames.thirdQuarter:
            displayName = t("model.WatchFixture.thirdQuarterDisplayName");
            break;
        case PeriodNames.fourthQuarter:
            displayName = t("model.WatchFixture.fourthQuarterDisplayName");
            break;
        case PeriodNames.firstBreak:
            displayName = t("model.WatchFixture.firstBreakDisplayName");
            break;
        case PeriodNames.secondBreak:
            displayName = t("model.WatchFixture.secondBreakDisplayName");
            break;
        case PeriodNames.thirdBreak:
            displayName = t("model.WatchFixture.thirdBreakDisplayName");
            break;
        case PeriodNames.et1:
            displayName = t("results.heatmap.extraTime1");
            break;
        case PeriodNames.et2:
            displayName = t("results.heatmap.extraTime2");
            break;
        case "Penalties":
            displayName = t("fixture.FixtureSummary.penalties");
            break;
        case "End of half":
            displayName = t("performance.Replay.endOfHalf");
            break;
        case "Abandoned":
            displayName = t("model.WatchFixture.abandonedDisplayName");
            break;
        case "End of Penalties":
            displayName = t("model.WatchFixture.penaltiesEndDisplayName");
            break;
        default:
            displayName = eventName;
            break;
    }
    return displayName;
}
