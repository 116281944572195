import { FC, useEffect, useState } from "react";
import { AdModel, AdSize } from "./AdModel";
import ReactGA from "react-ga4";
import { useInView } from "react-intersection-observer";

interface DisplayAdInput {
    ad: AdModel;
}

function getAdDimensions(size: AdSize): [number, number] {
    switch (size) {
        case AdSize.banner:
            return [320, 50];
        case AdSize.rectangle:
            // TODO should be 300x250 but capelli messed up
            return [320, 250];
        case AdSize.skyscraper:
            return [160, 600];
    }
    console.log(`Unknown ad size: ${size}`);
    return [0, 0];
}

const DisplayAd: FC<DisplayAdInput> = ({ ad }) => {
    const dimensions = getAdDimensions(ad.size);
    let [seen, setSeen] = useState<boolean>(() => false);
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });

    function adClick() {
        window.open(ad.targetUrl, "_blank");
        trackClick();
    }

    function trackClick() {
        ReactGA.event("custom_ad_click", {
            category: ad.advertiser,
            // action: "ad_click",
            label: ad.campaignName,
            ad_advertiser: ad.advertiser,
            ad_campaign: ad.campaignName,
            ad_size: ad.size,
            ad_creative: ad.creativeName,
            creative_name: ad.campaignName,
        });
    }

    function trackImpression() {
        ReactGA.event("custom_ad_impression", {
            category: ad.advertiser,
            label: ad.campaignName,
            advertiser: ad.advertiser,
            creative_name: ad.campaignName,
            nonInteraction: true,
        });
    }

    useEffect(() => {
        if (inView) {
            if (!seen) {
                trackImpression();
                setSeen(true);
            }
        }
    }, [inView]);

    return (
        <div
            style={{ textAlign: "center", width: "100%", cursor: "pointer" }}
            ref={ref}
        >
            <img
                src={ad.imageUrl}
                width={dimensions[0]}
                height={dimensions[1]}
                onClick={adClick}
            />
        </div>
    );
};

export default DisplayAd;
