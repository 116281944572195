import { AdModel, AdSize } from "./AdModel";
import ads from "./ads.json";

export interface ISizeAdMap {
    [size: string]: AdModel[];
}

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

/**
 * helper for Matches and results page has the logic of where to insert the ad.
 * @param index
 * @param totalMatches
 */
export function showBannerInMatchList(index: number, totalMatches: number) {
    return (
        (index + 2) % 3 === 0 || (totalMatches < 3 && index + 1 == totalMatches)
    );
}

export class AdService {
    static readonly instance: AdService = new AdService();

    ads: ISizeAdMap = {
        [AdSize.banner]: [],
        [AdSize.rectangle]: [],
        [AdSize.skyscraper]: [],
    };

    private constructor() {
        // TODO we should probably download from somewhere
        this.setAds(ads as AdModel[]);
    }

    setAds(ads: AdModel[]) {
        ads.forEach((ad) => {
            this.ads[ad.size].push(ad);
        });
    }

    getAd(size: AdSize): AdModel | null {
        const ads = this.ads[size];
        const selectedAd = ads[getRandomInt(0, ads.length)];
        if (selectedAd) {
            return { ...selectedAd };
        }
        return null;
    }
}
