import {
    AnyAction,
    applyMiddleware,
    combineReducers,
    createStore,
} from "redux";
import { StateType } from "typesafe-actions";
import { AppState } from "./AppState";
import thunk from "redux-thunk";
import matchesReducer from "./reducers/matchesReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import * as Sentry from "@sentry/react";
import competitionReducer from "./reducers/competitionReducer";
import selectedDayReducer from "./reducers/selectedDayReducer";
import { loadingMiddleware } from "./middleware/loadingMiddleware";

const appReducer = combineReducers<AppState>({
    matches: matchesReducer,
    competitions: competitionReducer,
    selectedDay: selectedDayReducer,
});

// reducers type
export type AppReducerType = ReturnType<typeof appReducer>;

const rootReducer = (state: AppReducerType | any, action: AnyAction) => {
    return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ["matches", "selectedDay", "competitions"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    {},
    composeWithDevToolsDevelopmentOnly(
        applyMiddleware(thunk, loadingMiddleware),
        Sentry.createReduxEnhancer()
    )
);

export const persistor = persistStore(store);

export type RootState = StateType<typeof appReducer>;
