import { FC, useState } from "react";
import {
    IonCard,
    IonCol,
    IonItem,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./teams.css";
import { IncidentEvent, Player, SelectedTeam } from "refsix-js-models";
import {
    generatePlayersEventsStructure,
    getAllPlayerForATeam,
    getAllPlayersIn,
    getAllSubs,
    getAllTeamOfficials,
    getPlayerIncidentIcon,
} from "../../utils/playersUtils";
import { getSelectedTeam } from "../../utils/teamUtils";
import ScrollableContent from "../../components/ScrollableContents";
import { MatchInput } from "./matchInput";
import { AdSize } from "../../components/ads/AdModel";
import AdPlacement from "../../components/ads/AdPlacement";

const Teams: FC<MatchInput> = ({ match }) => {
    const { t } = useTranslation();

    const [selectedValue, setSelectedValue] = useState<SelectedTeam>(
        SelectedTeam.home
    );

    // fix for deleting the match
    if (!match) {
        return <div />;
    }

    let playersWithEvents = generatePlayersEventsStructure(match);

    // Getting selected team
    let selectedTeam = getSelectedTeam(selectedValue);

    // Getting all players for selected team
    match.players = playersWithEvents;
    const players = getAllPlayerForATeam(match, selectedTeam);

    // Getting all players starting in
    let starting = getAllPlayersIn(players) as Player[];

    // Getting all players not starting in
    let subs = getAllSubs(players) as Player[];

    // Getting all team officials
    let teamOfficials = getAllTeamOfficials(players) as Player[];

    function handleTeamChanged(team: string) {
        if (team === "home") {
            setSelectedValue(SelectedTeam.home);
        } else {
            setSelectedValue(SelectedTeam.away);
        }
    }

    return (
        <ScrollableContent>
            <IonItem className="teams-segments-container" lines="none">
                <IonSegment
                    data-testid="segmentController"
                    value={selectedValue}
                    onIonChange={(e) =>
                        e.detail.value && handleTeamChanged(e.detail.value)
                    }
                >
                    <IonSegmentButton value="home">
                        {t("general.home")}
                    </IonSegmentButton>
                    <IonSegmentButton value="away">
                        {t("general.away")}
                    </IonSegmentButton>
                </IonSegment>
            </IonItem>

            <IonCard color="dark">
                <IonCard className="match-card-container" color="dark">
                    <div>
                        <IonText
                            className="match-details-title"
                            color="primary"
                        >
                            <h2>{t("fixture.FixtureTeam.lineUp")}</h2>
                        </IonText>
                    </div>
                    {starting.length <= 0 ? (
                        <div>
                            <IonText>
                                {t("integrations.teamSheets.noTeamSheet")}
                            </IonText>
                        </div>
                    ) : (
                        starting.map((player, index) => {
                            return (
                                <IonRow key={player.number}>
                                    <IonCol size="1.3">
                                        <IonText className="white-text-and-font">
                                            {player.number}
                                        </IonText>
                                    </IonCol>
                                    <IonRow className="team-name-row-container">
                                        <IonText className="white-text-and-font">
                                            {player.name}
                                        </IonText>
                                        {player.events &&
                                            Object.entries(player.events).map(
                                                ([ele, event]) => {
                                                    let incidentEvent =
                                                        undefined;

                                                    if (
                                                        event.eventName ===
                                                        "PenaltyShot"
                                                    ) {
                                                        return <div />;
                                                    }

                                                    if (
                                                        event.eventName ===
                                                        "Incident"
                                                    ) {
                                                        incidentEvent =
                                                            event as IncidentEvent;
                                                    }
                                                    return (
                                                        <IonRow
                                                            key={
                                                                event.timestamp
                                                            }
                                                        >
                                                            <div
                                                                className={`event-icons ${getPlayerIncidentIcon(
                                                                    event
                                                                )}`}
                                                            />
                                                            <IonText className="event-minute">
                                                                {incidentEvent !==
                                                                    undefined &&
                                                                    `(${incidentEvent.reason})`}
                                                            </IonText>
                                                            <IonText className="event-minute">
                                                                {
                                                                    event.minuteOfPlay
                                                                }
                                                                ´
                                                            </IonText>
                                                        </IonRow>
                                                    );
                                                }
                                            )}
                                    </IonRow>
                                </IonRow>
                            );
                        })
                    )}
                </IonCard>

                <AdPlacement size={AdSize.banner}></AdPlacement>

                <IonCard className="match-card-container" color="dark">
                    <div>
                        <IonText
                            className="match-details-title"
                            color="primary"
                        >
                            <h2>{t("fixture.FixtureTeam.substitutes")}</h2>
                        </IonText>
                    </div>
                    {subs.length <= 0 ? (
                        <div>
                            <IonText>
                                {t("integrations.teamSheets.noTeamSheet")}
                            </IonText>
                        </div>
                    ) : (
                        subs.map((player, index) => {
                            return (
                                <IonRow key={player.number}>
                                    <IonCol size="1.3">
                                        <IonText className="white-text-and-font">
                                            {player.number}
                                        </IonText>
                                    </IonCol>
                                    <IonRow className="team-name-row-container">
                                        <IonText className="white-text-and-font">
                                            {player.name}
                                        </IonText>
                                        {player.events &&
                                            Object.entries(player.events).map(
                                                ([ele, event]) => {
                                                    let incidentEvent =
                                                        undefined;

                                                    if (
                                                        event.eventName ===
                                                        "PenaltyShot"
                                                    ) {
                                                        return <div />;
                                                    }

                                                    if (
                                                        event.eventName ===
                                                        "Incident"
                                                    ) {
                                                        incidentEvent =
                                                            event as IncidentEvent;
                                                    }
                                                    return (
                                                        <IonRow
                                                            key={index}
                                                            color=""
                                                        >
                                                            <div
                                                                className={`event-icons ${getPlayerIncidentIcon(
                                                                    event
                                                                )}`}
                                                            />
                                                            <IonText className="event-minute">
                                                                {incidentEvent !==
                                                                    undefined &&
                                                                    `(${incidentEvent.reason})`}
                                                            </IonText>
                                                            <IonText className="event-minute">
                                                                {
                                                                    event.minuteOfPlay
                                                                }
                                                                ´
                                                            </IonText>
                                                        </IonRow>
                                                    );
                                                }
                                            )}
                                    </IonRow>
                                </IonRow>
                            );
                        })
                    )}
                </IonCard>

                <AdPlacement size={AdSize.banner}></AdPlacement>

                <IonCard className="match-card-container" color="dark">
                    <div>
                        <IonText
                            className="match-details-title"
                            color="primary"
                        >
                            <h2>{t("fixture.FixtureTeam.teamOfficials")}</h2>
                        </IonText>
                    </div>
                    {teamOfficials.length <= 0 ? (
                        <div>
                            <IonText>
                                {t("integrations.teamSheets.noTeamSheet")}
                            </IonText>
                        </div>
                    ) : (
                        teamOfficials.map((player, index) => {
                            return (
                                <IonRow
                                    key={player.number}
                                    className="team-name-row-container"
                                >
                                    <IonCol>
                                        <IonText className="white-text-and-font">
                                            {player.name}
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            );
                        })
                    )}
                </IonCard>
            </IonCard>
            <AdPlacement size={AdSize.rectangle}></AdPlacement>
        </ScrollableContent>
    );
};

export default Teams;
