import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonHeader,
    IonPage,
    IonProgressBar,
    IonText,
    useIonRouter,
} from "@ionic/react";
import "./Matches.css";
import { MatchPhone } from "refsix-js-models";
import { setSelectedMatch } from "../redux/actions/matches";
import MatchUpperCardComponent from "../components/MatchUpperCardComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import AdPlacement from "../components/ads/AdPlacement";
import { AdSize } from "../components/ads/AdModel";
import { showBannerInMatchList } from "../components/ads/AdService";
import { useAllMatches } from "../contexts/Competition";
import _ from "lodash";
import { useEffect, useState } from "react";
import DayPicker from "../components/DayPicker";
import moment from "moment";

const Matches: React.FC = () => {
    const dispatch = useDispatch();
    const route = useIonRouter();
    const matches = useAllMatches();
    const selectedDay = useSelector(
        (state: RootState) => state.selectedDay.selectedDay
    );
    const loading = useSelector((state: RootState) => state.matches.loading);

    const [matchesByCompetition, setMatchesByCompetition] = useState<
        Map<string, MatchPhone[]>
    >(new Map<string, MatchPhone[]>());

    const competitionGroup = useSelector(
        (state: RootState) => state.competitions.competitionFilter
    );

    const competitionsByGroup = useSelector(
        (state: RootState) => state.competitions.competitions
    );

    useEffect(() => {
        const matchesOfTheDay = _.filter(matches, (match) =>
            selectedDay.isSame(moment(match.date), "day")
        );
        const allCompetitions = _.uniq(_.map(matchesOfTheDay, "competition"));

        if (allCompetitions.length >= 1) {
            const matchesByCompetition = new Map<string, MatchPhone[]>();
            allCompetitions.forEach((competition) => {
                if (!competition) return;
                const compMatches = _.filter(matchesOfTheDay, { competition });
                matchesByCompetition.set(competition, compMatches);
            });
            setMatchesByCompetition(matchesByCompetition);
        } else {
            setMatchesByCompetition(new Map<string, MatchPhone[]>());
        }
    }, [matches]);

    const sortComps = (matchesByCompetitionArray: [string, MatchPhone[]][]) => {
        const sortedMatchesByCompetitionArray = [...matchesByCompetitionArray];
        sortedMatchesByCompetitionArray.sort((a, b) => {
            const orderA = competitionsByGroup.indexOf(a[0]);
            const orderB = competitionsByGroup.indexOf(b[0]);
            return orderA > orderB ? 1 : -1;
        });
        return sortedMatchesByCompetitionArray.map(([competition, matches]) => {
            const sortedMatches = _.sortBy(
                matches,
                (match: MatchPhone) => moment(match.date).valueOf(),
                "homeTeam"
            );
            return [competition, sortedMatches];
        });
    };

    function matchElements() {
        return matchesByCompetition.size > 0 ? (
            sortComps(Array.from(matchesByCompetition))
                // TODO sort by competition name

                .map(([competition, matches]) => {
                    return (
                        <IonCard
                            style={{
                                padding: 0,
                                background: "transparent",
                            }}
                            key={competition as string}
                        >
                            <IonCardHeader style={{ padding: 8 }}>
                                <h2
                                    style={{
                                        margin: 0,
                                        fontSize: 14,
                                        color: "var(--ion-color-primary)",
                                    }}
                                >
                                    {competition}
                                </h2>
                            </IonCardHeader>
                            <IonCardContent style={{ padding: "2px 0" }}>
                                {Array.isArray(matches) &&
                                    matches.map((match, index) => {
                                        return (
                                            <div key={match._id}>
                                                <IonCard
                                                    style={{
                                                        marginTop: 0,
                                                        marginLeft: 2,
                                                        marginRight: 2,
                                                        marginBottom: 10,
                                                    }}
                                                    color="dark"
                                                    className="match-card"
                                                    onClick={() => {
                                                        dispatch(
                                                            setSelectedMatch(
                                                                match
                                                            )
                                                        );
                                                        route.push(
                                                            `/${competitionGroup}/match/` +
                                                                match._id,
                                                            "forward"
                                                        );
                                                    }}
                                                >
                                                    <MatchUpperCardComponent
                                                        match={match}
                                                    />
                                                </IonCard>
                                                {showBannerInMatchList(
                                                    index,
                                                    matches.length
                                                ) && (
                                                    <AdPlacement
                                                        size={AdSize.banner}
                                                    ></AdPlacement>
                                                )}
                                            </div>
                                        );
                                    })}
                            </IonCardContent>
                        </IonCard>
                    );
                })
        ) : (
            <IonCard className="no-upcoming-matches" color="dark">
                {/* TODO proper translation */}
                <IonText data-testid="noEndedFixturesTxt">
                    No matches for selected day.
                </IonText>
            </IonCard>
        );
    }

    return (
        <IonPage>
            <IonContent>
                <IonHeader></IonHeader>
                <DayPicker></DayPicker>
                <IonProgressBar
                    type="indeterminate"
                    hidden={loading !== true}
                ></IonProgressBar>

                {matchElements()}

                <AdPlacement size={AdSize.rectangle}></AdPlacement>
            </IonContent>
        </IonPage>
    );
};

export default Matches;
