import { FC } from "react";
import { IonText } from "@ionic/react";
import TeamLogoComponent from "./TeamLogoComponent";
import { MatchScore, ScoreType } from "./MatchScore";
import { MatchPhone } from "refsix-js-models";
import "./TeamRowComponent.css";

interface TeamRowComponentProps {
    match: MatchPhone;
    teamName: string;
    scoreType: ScoreType;
}

const TeamRowComponent: FC<TeamRowComponentProps> = ({
    match,
    teamName,
    scoreType,
}) => {
    return (
        <div color="transparent" className="team-row">
            <div className="team-logo">
                <TeamLogoComponent teamName={teamName} />
            </div>

            <div className="team-name">
                <IonText>{teamName}</IonText>
            </div>

            <div className="team-score">
                <MatchScore
                    match={match}
                    scoreType={scoreType}
                    color="primary"
                />
            </div>
        </div>
    );
};

export default TeamRowComponent;
