import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Matches from "./pages/Matches";
import Table from "./pages/table/Table";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";
import FixturesSegments from "./pages/fixturesSegments/fixturesSegments";
import { PageViewAnalytics } from "./components/pageViewAnalytics";
import { useEffect } from "react";
import Settings from "./pages/settings/settings";
import AdPlacement from "./components/ads/AdPlacement";
import { AdSize } from "./components/ads/AdModel";
import { RouteComponentProps } from "react-router";
import { filter } from "lodash/fp";
import {
    setCompetitionFilter,
    setCompetitions,
    setSelectedCompetition,
} from "./redux/actions/competitions";
import { useDispatch } from "react-redux";
import { store } from "./redux/store";
import moment, { Moment } from "moment/moment";
import {
    getCompetitionGroup,
    getMatches,
    listenForMatchUpdates,
} from "./services/matchesApiService";
import { setLoadingMatches, setMatches } from "./redux/actions/matches";

setupIonicReact();

const App: React.FC = () => {
    useEffect(() => {
        // startDatabase();

        // Set the name of the hidden property and the change event for visibility
        let hidden: any;
        let visibilityChange;
        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else {
            // @ts-ignore
            if (typeof document.msHidden !== "undefined") {
                hidden = "msHidden";
                visibilityChange = "msvisibilitychange";
                // @ts-ignore
            } else if (typeof document.webkitHidden !== "undefined") {
                hidden = "webkitHidden";
                visibilityChange = "webkitvisibilitychange";
            }
        }

        function handleVisibilityChange() {
            // @ts-ignore
            if (document[hidden]) {
                // TODO pause live SSE updates
                console.log("Hidden");
            } else {
                // TODO resume live SSE updates and reload current date
                console.log("Visible");
            }
        }

        // Warn if the browser doesn't support addEventListener or the Page Visibility API
        if (
            typeof document.addEventListener === "undefined" ||
            hidden === undefined
        ) {
            console.log(
                "This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API."
            );
        } else {
            // Handle page visibility change

            document.addEventListener(
                // @ts-ignore
                visibilityChange,
                handleVisibilityChange,
                false
            );
        }

        return () => {
            // cancel();
        };
    }, []);
    return (
        <IonApp>
            <div className="skyscraper" style={{ left: "0" }}>
                <AdPlacement size={AdSize.skyscraper}></AdPlacement>
            </div>
            <div className="skyscraper" style={{ right: "0" }}>
                <AdPlacement size={AdSize.skyscraper}></AdPlacement>
            </div>

            <IonReactRouter>
                <PageViewAnalytics />
                <Route path={`/`} exact={true}>
                    <Redirect to={`/all/`} />
                </Route>
                <Route path="/settings">
                    <Settings />
                </Route>

                <Route path="/:comp/" component={CompetitionRouter} />
                <Route path="/:comp/:action" component={CompetitionRouter} />
            </IonReactRouter>
        </IonApp>
    );
};

interface CompetitionRouterProps
    extends RouteComponentProps<{ comp: string, action?: string }> {}

const CompetitionRouter: React.FC<CompetitionRouterProps> = ({ match }) => {
    const competitionFilter = match.params.comp;
    const  action = match.params.action;
    const dispatch = useDispatch();
    const parseCompetitionFilter = (text: string) =>
        filter(Boolean, text.split("\n"));

    useEffect(() => {
        const run = async () => {
            dispatch(setLoadingMatches(true));
            dispatch(setCompetitionFilter(competitionFilter));

            const initialDay = store.getState().selectedDay;
            let currentDay: Moment = initialDay.selectedDay;

            // live updates
            const competitionGroup = competitionFilter;
            const startTs = initialDay.startTs;
            const endTs = initialDay.endTs;

            store.dispatch(setCompetitionFilter(competitionGroup));

            try {
                const compGroup = await getCompetitionGroup(competitionGroup);
                if (compGroup.data && compGroup.data.competitions) {
                    store.dispatch(
                        setCompetitions(compGroup.data.competitions)
                    );
                }
                if(action === "table") {
                    store.dispatch(setSelectedCompetition(compGroup.data.competitions[0]));
                }
            } catch (e) {
                console.log("Error getting competition group: ", e);
            }

            try {
                // start listening for live updates for matches +/- 1 day
                listenForMatchUpdates(
                    competitionGroup,
                    moment().subtract(1, "day").valueOf(),
                    moment().add(1, "day").valueOf()
                );
                if(action !== "table") {

                    // get the initial state
                    const matches = await getMatches(
                      competitionGroup,
                      startTs,
                      endTs
                    );
                    store.dispatch(setMatches(matches.data));
                }
                dispatch(setLoadingMatches(false));
            } catch (err: any) {
                dispatch(setLoadingMatches(false));
                console.log("Something bad went down. Error: ", { err });
                throw err;
            }

            store.subscribe(async () => {
                const state = store.getState();
                if (currentDay.isSame(state.selectedDay.selectedDay, "day")) {
                    return;
                }
                currentDay = state.selectedDay.selectedDay;
                const startTs = state.selectedDay.startTs;
                const endTs = state.selectedDay.endTs;
                try {
                    if(action !== "table") {
                        const matches = await getMatches(
                          competitionGroup,
                          startTs,
                          endTs
                        );
                        store.dispatch(setMatches(matches.data));
                    }
                } catch (e) {
                    store.dispatch(setLoadingMatches(false));
                }
            });
        };
        run();
    }, [competitionFilter]);

    // useEffect(() => {
    // TODO set up new comp stuff here?
    // const runAsync = async () => {
    //     try {
    //         const res = await fetch(
    //             `https://assets.grassrootslive.app/competition-filters/${competitionFilter}`
    //         );
    //         if (!res.ok) {
    //             dispatch(setCompetitions(allCompetitions));
    //             dispatch(setSelectedCompetition(allCompetitions[0]));
    //         } else {
    //             const text = await res?.text();
    //             const comps = parseCompetitionFilter(text);
    //
    //             dispatch(setSelectedCompetition(comps[0]));
    //             dispatch(setCompetitions(comps));
    //         }
    //         return;
    //     } catch (e) {
    //         console.log(e);
    //         //setCompetitions([]);
    //     }
    //     dispatch(setCompetitions(allCompetitions));
    // };
    // if (competitionFilter === "all") {
    //     dispatch(setCompetitions(allCompetitions));
    // } else {
    //     runAsync();
    // }
    // }, [competitionFilter, allCompetitions]);

    return (
        // <IonTabs>
        <IonRouterOutlet>
            <Route exact path={`/${competitionFilter}/matches`}>
                <Matches />
            </Route>
            <Route path={`/${competitionFilter}/table`}>
                <Table />
            </Route>
            <Route
                exact
                path={`/${competitionFilter}/match/:id`}
                component={FixturesSegments}
            />
            <Route exact path={`/${competitionFilter}/`}>
                <Redirect to={`/${competitionFilter}/matches`} />
            </Route>
            <Route
                exact
                path={`/${competitionFilter}/matches`}
                component={Matches}
            />
        </IonRouterOutlet>

        // {/*<IonTabBar slot="bottom" color="dark">*/}
        // {/*    <IonTabButton tab="tab1" href={`/${competitionFilter}/matches`}>*/}
        // {/*        <IonIcon icon={footballOutline} />*/}
        // {/*        <IonLabel>Matches</IonLabel>*/}
        // {/*    </IonTabButton>*/}
        // {/*    <IonTabButton tab="tab2" href={`/${competitionFilter}/results`}>*/}
        // {/*        <IonIcon icon={listOutline} />*/}
        // {/*        <IonLabel>Results</IonLabel>*/}
        // {/*    </IonTabButton>*/}
        // {/*    <IonTabButton tab="tab3" href={`/${competitionFilter}/table`}>*/}
        // {/*        <IonIcon icon={podiumOutline} />*/}
        // {/*        <IonLabel>Table</IonLabel>*/}
        // {/*    </IonTabButton>*/}
        // {/*    /!*<IonTabButton tab="tab4" href="/settings">*!/*/}
        // {/*    /!*    <IonIcon icon={settingsOutline} />*!/*/}
        // {/*    /!*    <IonLabel>Settings</IonLabel>*!/*/}
        // {/*    /!*</IonTabButton>*!/*/}
        // {/*</IonTabBar>*/}
        // </IonTabs>
    );
};

export default App;
