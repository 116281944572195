import {
    EventName,
    GoalEvent,
    HalfEvent,
    MatchPhone,
    SelectedTeam,
} from "refsix-js-models";
import { copyObject } from "refsix-core";
import { flow, uniq } from "lodash";

const DATABASE_NOT_INITIALISED = "Database not initialised";
const OFFICIAL_ROLES = [
    "referee",
    "assistant1",
    "assistant2",
    "fourthOfficial",
    "observer",
];

const DB_URL = process.env.REACT_APP_DB_URL;
// LIVE TEST
// const DB_URL =
//     "https://sfllondon:xamt6Sz97dtDcWPbfzV6@grassroots-live.dev.refsix.com:5984/test-accounts";

// TODO setting, template

export async function startDatabase() {
    // const initialDay = store.getState().selectedDay;
    // let currentDay: Moment = initialDay.selectedDay;
    //
    // // live updates
    // const competitionGroup = "smafa-admin";
    // const startTs = initialDay.startTs;
    // const endTs = initialDay.endTs;
    //
    // store.dispatch(setCompetitionFilter(competitionGroup));
    //
    // try {
    //     const compGroup = await getCompetitionGroup(competitionGroup);
    //     if (compGroup.data && compGroup.data.competitions) {
    //         store.dispatch(setCompetitions(compGroup.data.competitions));
    //     }
    // } catch (e) {
    //     console.log("Error getting competition group: ", e);
    // }
    //
    // try {
    //     // start listening for live updates for matches +/- 1 day
    //     await listenForMatchUpdates(
    //         competitionGroup,
    //         moment().subtract(1, "day").valueOf(),
    //         moment().add(1, "day").valueOf()
    //     );
    //     // get the initial state
    //     const matches = await getMatches(competitionGroup, startTs, endTs);
    //     store.dispatch(setMatches(matches.data));
    // } catch (err: any) {
    //     console.log("Something bad went down. Error: ", { err });
    //     throw err;
    // }
    //
    // store.subscribe(async () => {
    //     const state = store.getState();
    //     if (currentDay.isSame(state.selectedDay.selectedDay, "day")) {
    //         return;
    //     }
    //     currentDay = state.selectedDay.selectedDay;
    //     const startTs = state.selectedDay.startTs;
    //     const endTs = state.selectedDay.endTs;
    //     const matches = await getMatches(competitionGroup, startTs, endTs);
    //     store.dispatch(setMatches(matches.data));
    // });
    // get initial matches
}

export function filterResults(matches: MatchPhone[]): MatchPhone[] {
    let filteredMatches = matches.filter(function (match: MatchPhone) {
        return match.matchFinished;
    });

    return matchesSortedByDate(filteredMatches).reverse();
}

export function filterResultsInProgressOrPlayed(
    matches: MatchPhone[]
): MatchPhone[] {
    let filteredMatches = matches.filter(function (match: MatchPhone) {
        return match.matchInProgress || match.matchFinished;
    });

    return matchesSortedByDate(filteredMatches).reverse();
}

export function getMatchesForTeam(matches: MatchPhone[], teamName: string) {
    return matches.filter(function (match) {
        return match.homeTeam === teamName || match.awayTeam === teamName;
    });
}

export function filterMatches(matches: MatchPhone[]): MatchPhone[] {
    let filteredMatches = matches.filter(function (match: MatchPhone) {
        return !match.matchFinished;
    });

    return matchesSortedByDate(filteredMatches);
}

export function allMatches(matches: MatchPhone[]): MatchPhone[] {
    return matchesSortedByDate(matches);
}

export function matchesSortedByDate(matches: MatchPhone[]): MatchPhone[] {
    return matches.sort(
        (matchA, matchB) =>
            new Date(matchA.date as string).getTime() -
            new Date(matchB.date as string).getTime()
    );
}

export function getCompetitions(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.competition || "";
        })
    );
}

export function getHomeTeams(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.homeTeam;
        })
    );
}

export function getAwayTeams(matches: MatchPhone[]) {
    return uniq(
        matches.map(function (match) {
            return match.awayTeam;
        })
    );
}

export function getMatchOfficials(matches: MatchPhone[]) {
    let allReferees = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.referee
                ? match.matchOfficials?.referee
                : "";
        })
    );
    let allAssistant1 = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.assistant1
                ? match.matchOfficials?.assistant1
                : "";
        })
    );
    let allAssistant2 = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.assistant2
                ? match.matchOfficials?.assistant2
                : "";
        })
    );
    let allFourthOfficials = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.fourthOfficial
                ? match.matchOfficials?.fourthOfficial
                : "";
        })
    );
    let allObservers = uniq(
        matches.map(function (match) {
            return match.matchOfficials?.observer
                ? match.matchOfficials?.observer
                : "";
        })
    );

    return [
        ...allReferees,
        ...allAssistant1,
        ...allAssistant2,
        ...allFourthOfficials,
        ...allObservers,
    ].filter(function (el) {
        return el !== "" && el !== "currentUser";
    });
}

export function getMatchById(matches: MatchPhone[], id: string) {
    return matches.find(function (match) {
        return match._id === id;
    });
}

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
    );
}

function getPlayingSegments(match: MatchPhone) {
    const halfEvents: HalfEvent[] = [];
    const matchCopy = copyObject(match);

    if (matchCopy.matchEvents) {
        for (const prop in matchCopy.matchEvents) {
            if (matchCopy.matchEvents.hasOwnProperty(prop)) {
                const event = matchCopy.matchEvents[prop];
                const eventName = event.eventName
                    ? event.eventName.trim()
                    : event.eventName;
                if (eventName === EventName.half) {
                    const halfEvent = event as HalfEvent;
                    if (halfEvent.playing) {
                        halfEvent.length = millisToMins(halfEvent.length);
                        halfEvents.push(halfEvent);
                    }
                }
            }
        }
    }
    return halfEvents.sort(function (a, b) {
        return a.timestamp - b.timestamp;
    });
}

function millisToMins(millis: number) {
    return millis && !isNaN(millis) ? Math.round(millis / 60000) : millis;
}

// Returning all the teams in for the specific competition
export function getTeamsInCompetition(
    allMatches: MatchPhone[],
    competition: string
) {
    const allTeams: String[] = [];
    allMatches.forEach((match: MatchPhone) => {
        if (match.competition === competition) {
            allTeams.push(match.homeTeam);
            allTeams.push(match.awayTeam);
        }
    });
    return uniq(allTeams);
}

export function getPtsForTeam(
    match: MatchPhone,
    score: [number, number],
    side: number
) {
    const currentTeamScore = side === 0 ? score[0] : score[1];
    const oppositeTeamScore = side === 0 ? score[1] : score[0];

    if (currentTeamScore > oppositeTeamScore) {
        return 3;
    } else if (currentTeamScore === oppositeTeamScore) {
        return 1;
    }

    return 0;
}

export function calculateTeamStats(matches: MatchPhone[], teamName: string) {
    let goalsScored: number = 0;
    let goalsAgainst: number = 0;
    let points: number = 0;
    let matchInProgress: boolean = false;
    let isHomeTeam: boolean = false;
    matches.map((match) => {
        const score = calculateScore(match);
        isHomeTeam = match.homeTeam === teamName;
        if (match.matchInProgress) {
            matchInProgress = true;
        }
        if (isHomeTeam) {
            goalsScored += score[0];
            goalsAgainst += score[1];
            points += getPtsForTeam(match, score, 0);
        } else {
            goalsScored += score[1];
            goalsAgainst += score[0];
            points += getPtsForTeam(match, score, 1);
        }
    });
    return { goalsScored, goalsAgainst, points, matchInProgress };
}

export function calculateScore(match: MatchPhone): [number, number] {
    if (!match) {
        return [0, 0];
    }
    const events = Object.values(match.matchEvents || {});
    const goals = events.filter((event) => {
        return event.eventName === "Goal";
    });

    return goals.reduce(
        (result, goal): [number, number] => {
            const goalEvent = goal as GoalEvent;
            if (goalEvent.team.side === SelectedTeam.home) {
                result[0]++;
            } else if (goalEvent.team.side === SelectedTeam.away) {
                result[1]++;
            }
            return result;
        },
        [0, 0]
    );
}

/**
 * Returns a new array with the items sorted alphanumerically (including non-ASCII)
 */
export function sort(array: string[]): string[] {
    return [...array].sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    );
}

/**
 * Returns a new array with items sorted alphanumerically (including non-ASCII) and without duplicates
 */
export function uniqSort(array: string[]): string[] {
    return flow(uniq, sort)(array);
}

export function getLogoUrlForTeam(teamName: string) {
    const trimmedTeamName = teamName.replace(" ", "-").toLowerCase();
    return `https://assets.grassrootslive.app/team-logos/${trimmedTeamName}.png`;
}
