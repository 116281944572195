import { FC } from "react";
import {
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./settings.css";

const Settings: FC = () => {
    const { t } = useTranslation();
    const emailFeedbackTo = "grassrootslive@refsix.com";
    const subjectFeedback = "Grassroots Live Feedback";
    const emailRequestALeagueTo = "grassrootslive@refsix.com";
    const subjectRequestALeague = "Request A League";

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle size="large">{t("general.settings")}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonList className="transparent-list">
                    <IonItem
                        color="white"
                        lines="none"
                        button
                        href={`mailto:${emailFeedbackTo}"?subject=${subjectFeedback}`}
                        data-testid="feedback-button"
                    >
                        <IonGrid>
                            <IonLabel className="bold">
                                {t("settings.feedback.menu")}
                            </IonLabel>
                            <IonText className="sub-title">
                                {t("settings.feedback.subTitle")}
                            </IonText>
                        </IonGrid>
                    </IonItem>
                    {/*<IonItem*/}
                    {/*    color="white"*/}
                    {/*    lines="none"*/}
                    {/*    button*/}
                    {/*    onClick={() =>*/}
                    {/*        window.open(*/}
                    {/*            isAndroid()*/}
                    {/*                ? URLS.PLAY_STORE_URL*/}
                    {/*                : URLS.APP_STORE_URL,*/}
                    {/*            "_system"*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*    data-testid="review-app-button"*/}
                    {/*>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonLabel className="bold">*/}
                    {/*            {t("settings.Review.reviewApp")}*/}
                    {/*        </IonLabel>*/}
                    {/*        <IonText className="sub-title">*/}
                    {/*            {t("settings.Review.subTitle")}*/}
                    {/*        </IonText>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}

                    {/*<IonItem*/}
                    {/*    color="white"*/}
                    {/*    lines="none"*/}
                    {/*    data-testid="notifications-toggle-button"*/}
                    {/*>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonLabel className="bold">*/}
                    {/*            {t("settings.liveScore.menu")}*/}
                    {/*        </IonLabel>*/}
                    {/*        <IonText className="sub-title">*/}
                    {/*            {t("settings.liveScore.subTitle")}*/}
                    {/*        </IonText>*/}
                    {/*    </IonGrid>*/}
                    {/*    <IonToggle />*/}
                    {/*</IonItem>*/}
                    <IonItem
                        color="white"
                        lines="none"
                        button
                        href={`mailto:${emailRequestALeagueTo}"?subject=${subjectRequestALeague}`}
                        data-testid="request-league-button"
                    >
                        <IonGrid>
                            <IonLabel className="bold">
                                {t("settings.requestLeague.menu")}
                            </IonLabel>
                            <IonText className="sub-title">
                                {t("settings.requestLeague.subtitle")}
                            </IonText>
                        </IonGrid>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Settings;
