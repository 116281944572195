import {
    EventName,
    GoalEvent,
    IncidentEvent,
    MatchEvent,
    PenaltyShotEvent,
    SelectedTeam,
    SubstitutionEvent,
} from "refsix-js-models";

export function getSelectedTeam(team: SelectedTeam): SelectedTeam {
    if (team === "home") {
        return SelectedTeam.home;
    }

    return SelectedTeam.away;
}

export function getTeamSide(event: MatchEvent) {
    if (event.eventName === EventName.goal) {
        let goalEvent = event as GoalEvent;
        return goalEvent.team.side;
    } else if (event.eventName === EventName.incident) {
        let incidentEvent = event as IncidentEvent;
        return incidentEvent.team.side;
    } else if (event.eventName === EventName.penaltyShot) {
        let penaltyShotEvent = event as PenaltyShotEvent;
        return penaltyShotEvent.team.side;
    } else if (event.eventName === EventName.substitution) {
        let subsEvent = event as SubstitutionEvent;
        return subsEvent.team.side;
    }

    return "home";
}

export function getStringForEvent(event: MatchEvent, playerOff?: boolean) {
    if (event.eventName === EventName.goal) {
        let goalEvent = event as GoalEvent;
        return goalEvent.player !== undefined
            ? `#${goalEvent.player?.number} ${goalEvent.player?.name}`
            : "";
    } else if (event.eventName === EventName.incident) {
        let incidentEvent = event as IncidentEvent;
        return incidentEvent.player !== undefined
            ? `#${incidentEvent.player?.number} ${incidentEvent.player?.name} (${incidentEvent.reason})`
            : "";
    } else if (event.eventName === EventName.penaltyShot) {
        let penaltyShotEvent = event as PenaltyShotEvent;
        return penaltyShotEvent.player !== undefined
            ? `#${penaltyShotEvent.player?.number} ${penaltyShotEvent.player?.name}`
            : "";
    } else if (event.eventName === EventName.substitution) {
        let subsEvent = event as SubstitutionEvent;
        if (subsEvent.playerOn && !playerOff) {
            return `#${subsEvent.playerOn.number} ${subsEvent.playerOn.name}`;
        } else if (subsEvent.playerOff) {
            return `(#${subsEvent.playerOff.number} ${subsEvent.playerOff.name})`;
        } else {
            return "";
        }
    }
    return "";
}
