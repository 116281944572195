import { FC } from "react";
import { IonCol, IonItem, IonText } from "@ionic/react";
import "./Table.css";

const TableHeader: FC = () => {
    const tableHeader: string[] = ["POS", "TEAM", "P", "GD", "PTS"];

    return (
        <IonItem color="transparent" className="header-container" lines="full">
            <IonCol size="1.5" className="center">
                <IonText className="text-small">{tableHeader[0]}</IonText>
            </IonCol>
            <IonCol size={"6.5"}>
                <IonText className="text-small">{tableHeader[1]}</IonText>
            </IonCol>
            <IonCol size={"1"} className="center">
                <IonText className="text-small">{tableHeader[2]}</IonText>
            </IonCol>
            <IonCol size={"2"} className="center">
                <IonText className="text-small">{tableHeader[3]}</IonText>
            </IonCol>
            <IonCol size={"1"} className="center">
                <IonText className="text-small">{tableHeader[4]}</IonText>
            </IonCol>
        </IonItem>
    );
};

export default TableHeader;
