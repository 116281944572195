import { FC, useEffect, useRef, useState } from "react";

const ScrollableContent: FC = ({ children }) => {
    const domElement = useRef<HTMLDivElement | null>(null);

    const [height, setHeight] = useState<string>("3000px");

    useEffect(() => {
        updateSectionHeight();
        window.addEventListener("resize", updateSectionHeight);
        return () => {
            // remove listeners when the component is destroyed
            window.removeEventListener("resize", updateSectionHeight);
        };
    }, [height, children]);

    function updateSectionHeight() {
        if (height !== getContainerHeight()) {
            setHeight(getContainerHeight());
        }
    }

    function getContainerHeight(): string {
        const tabBar = document.querySelector("ion-tab-bar");
        const tabBarHeight = tabBar
            ? tabBar.getBoundingClientRect().height + 5
            : 0;

        if (domElement && domElement.current) {
            return (
                window.innerHeight -
                domElement.current.getBoundingClientRect().top -
                // account for tab bar
                tabBarHeight +
                "px"
            );
        }
        return `${window.innerHeight} px`;
    }

    return (
        <div
            style={{ overflowY: "scroll", height: height }}
            ref={domElement}
            className="scrollable-contents"
        >
            {children}
        </div>
    );
};

export default ScrollableContent;
